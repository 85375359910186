.t-404
    min-height: 100vh
    display: flex
    flex-direction: column
    justify-content: space-between
    &.t-work-area
        padding: 170px 80px 100px 252px

    &__block
        width: 112px
        height: 112px
        border-radius: 50%
        background-color: $text-add
        color: #ffffff
        font-size: 42px
        margin-bottom: 35px
        display: flex
        justify-content: center
        align-items: center
        font-family: 'manrope', Arial, sans-serif
        font-weight: 300

    &__title
        font-size: 36px
        max-width: 450px
        font-family: 'manrope', Arial, sans-serif
        font-weight: 300
        line-height: 1.33
        margin-bottom: 50px

    &__text
        font-size: 14px
        max-width: 348px
        margin-bottom: 35px

    &__container
        display: flex
        margin-bottom: 100px

    &__btn
        padding: 11px 30px
        span
            font-size: 14px
            font-weight: 300
        &:before
            background: linear-gradient(75deg, #00d300, #0fd3d3)
        &._left
            margin-right: 20px
        .t-btn_img
            background-image: url('/img/ico-it-btn.png')
            opacity: .5
        &._img
            padding: 11px 60px 11px 30px

    &__bottom
        max-width: 765px
        width: calc(100% - 310px)
        min-width: 480px
        display: flex
        justify-content: space-between
        align-items: center
        span
            color: #bdcfd1

    &-one
        .t-one
            transform: translate3d(calc(100% - 345px - (100vw - 1440px)/2), 0, 0) scale3d(1, 1, 1)
            &._background
                margin: 204px 200px 0 0
                .t-one__top
                    border: none
                    clip-path: polygon(0 100%, 4% 0, 100% 0, 100% 100%)
                .t-one__bottom
                    background: radial-gradient(circle, $color-1 $max, transparent $max*1.3) 0 0, radial-gradient(circle, $color-1 $max, transparent $max*1.3) $size $size
                    background-size: $big-size $big-size
                    background-color: transparent
                    background-repeat: repeat


@media (max-width: 1440px)
    .t-404
        &-one .t-one
            transform: translate3d(calc(100% - 345px), 0, 0) scale3d(1, 1, 1)

@media (max-width: 1280px)
    .t-404
        &.t-work-area
            padding: 120px 80px 100px 80px

@media (max-width: 1000px)
    .t-404
        &-one .t-one
            transform: translate3d(calc(100% - 77px), 0, 0) scale3d(1, 1, 1)
            &._background
                display: none
        &-one.t-one-container
            margin-top: -46px
            height: calc(100% + 46px)

@media (max-width: 767px)
    .t-404
        &.t-work-area
            padding: 22px 66px 28px 16px

        &__block
            width: 54px
            height: 54px
            font-size: 20px
            margin-bottom: 18px

        &__title
            font-size: 25px
            margin-bottom: 20px

        &__text
            font-size: 12px
            margin-bottom: 25px

        &__container
            margin-bottom: 50px
            flex-direction: column

        &__btn
            margin-bottom: 10px
            width: 100%
            display: flex
            justify-content: center
            &._left
                margin-right: 0
                &:before
                    background: linear-gradient(259deg, #00db00, #00b500)
            &._img
                padding: 11px 30px
                &:before
                    background: rgba(#bdcfd1, .26)
                .t-btn_img, .t-btn_number
                    display: none
                span
                    color: $text-base

        &__bottom
            min-width: 100%
            .t-logo
                display: none
            span
                font-size: 12px