header
    position: absolute
    top: 0
    left: 0
    width: 100%
    z-index: 1
    &._main
        .t-logo_main
            display: block !important
            min-height: 80px
        .t-logo_not-main
            display: none

.t-header
    padding: 55px 79px
    max-width: 1440px
    margin: 0 auto
    width: 100%
    display: flex
    -ms-overflow-style: none
    scrollbar-width: none
    &::-webkit-scrollbar
        display: none
        width: 0

.t-logo
    width: 138px
    margin-right: 97px
    z-index: 1
    &._hidden
        width: 0
    a
        display: block
        transition: transform .3s
        &:active
            transform: scale(.97)
    svg
        display: block
        width: 100%
        height: 100%
        g
            transition: fill .3s
    &:active
        svg g
            fill: #00b500
    @media (min-width: 1024px)
        &:hover
            svg g
                fill: #00b500
    &_main
        display: none !important

._logo-mobile
    display: none
    min-height: 47px
    @media (max-width: 767px)
        display: block
        &_hidden
            display: none


.t-nav
    margin-left: 107px
    ul
        display: flex
        align-items: center
        list-style-type: none
    &__link
        margin-right: 48px
        position: relative
        color: #a3b6b8
        font-weight: 400
        white-space: nowrap
        display: block
        transition: color .3s, transform .3s
        &._active
            color: $text-base
            pointer-events: none
            &:after
                content: ''
                position: absolute
                top: 5px
                left: -26px
                width: 21px
                height: 15px
                clip-path: polygon(0 100%,69.7% 0,100% 0,30.3% 100%)
                -webkit-clip-path: polygon(0 100%,69.7% 0,100% 0,30.3% 100%)
        &._vacancies:after
            background: linear-gradient(126deg, #00d300, #0fd3d3)
        &._it-vacancies:after
            background: linear-gradient(55deg, #00d300, #0fd3d3)
        &._banking-vacancies:after
            background: linear-gradient(234deg, #13e0e0, #08bdbd)
        &:active
            transform: scale(.93)
        @media (min-width: 1024px)
            &:hover
                color: $text-base


.t-burger
    display: none
    width: 32px
    height: 32px
    background-color: #ffffff
    border-radius: 50%
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2)
    align-items: center
    justify-content: center
    position: fixed
    top: 20px
    right: 16px
    z-index: 20
    //cursor: pointer
    &._open
        span
            background-color: #ffffff
            &:before
                transform: rotate(135deg) translateY(0)
            &:after
                transform: rotate(-135deg) translateY(0)
    span
        position: relative
        width: 14px
        height: 2px
        background-color: #00d800
        transition: background-color 0s
        &:before, &:after
            content: ''
            position: absolute
            width: 14px
            height: 2px
            background-color: #00d800
            transition: transform .3s
        &:before
            transform: translateY(-4px)
        &:after
            transform: translateY(4px)

    &-content
        position: fixed
        width: 100%
        height: 100vh
        top: 0
        left: 0
        background-color: #F0F0F0
        padding-top: 48px
        opacity: 0
        pointer-events: none
        transition: opacity .4s

        @media (max-width: 920px) 
            padding-top: 97px
            
        &._open
            opacity: 1
            pointer-events: auto

        &__container
            display: flex
        &__info
            width: 50%
            padding: 10px 0
            display: flex
            justify-content: center
            border: 1px solid rgba(#bdcfd1, .5)
            &:first-child
                border-right: transparent
        &__block
            padding: 30px 56px
            justify-content: center
            background-color: #ffffff
        &__address
            font-weight: 600
            margin-bottom: 10px
            text-align: center
        &__time
            color: #78979a
            padding: 0 20px
            text-align: center
        &__bottom
            padding: 14px 0 16px
            display: flex
            justify-content: center
            color: #bdcfd1
            font-size: 12px



.t-nav-mobile
    padding: 0 16px
    margin-bottom: 24px
    ul
        list-style-type: none
        li
            display: flex
            align-items: center
    &__link
        padding: 16px 0
        font-size: 22px
        font-family: 'manrope', Arial, sans-serif
        font-weight: 300
        line-height: 1.2
        &._active
            background: linear-gradient(102deg, #00d300, #0fd3d3)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent




@media (max-width: 1320px)
    .t-nav
        margin-left: 5px


@media (max-width: 1000px)
    header:not(._main)
        .t-logo
            display: none

    .t-header
        padding: 30px 40px


@media (max-width: 767px)
    header
        z-index: 20
    header:not(._main)
        overflow-x: auto
        -webkit-overflow-scrolling: touch
        touch-action: pan-x
        background-color: #ffffff
        
        &::-webkit-scrollbar
            display: none
            width: 0

        .t-header
            padding: 10px 16px
            width: fit-content
            min-width: 100%
            background-color: rgba(#bdcfd1, .2)

    .t-header
        padding: 20px 16px

    .t-nav
        margin-left: 20px
        &__link
            margin-right: 40px
            &._active:after
                left: -20px
                width: 16px
                height: 13px

    .t-logo
        width: 100px
        min-width: 100px
        margin-right: 0
        z-index: 0
        &._hidden
            min-width: 0
            width: 0 !important

    .t-burger._visible
        display: flex
