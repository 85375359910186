.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide, .swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.swiper-invisible-blank-slide {
  visibility: hidden;
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}

.swiper-container-3d {
  perspective: 1200px;
}

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal > .swiper-wrapper {
  touch-action: pan-y;
}

.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical > .swiper-wrapper {
  touch-action: pan-x;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  width: 27px;
  height: 44px;
  margin-top: -23px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-lock {
  display: none;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block;
}

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  transition: 200ms transform, 200ms top;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms left;
}

.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms right;
}

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transform-origin: left top;
}

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progressbar {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-vertical > .swiper-pagination-progressbar {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #ffffff;
}

.swiper-pagination-progressbar.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.25);
}

.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
  background: #ffffff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000000;
}

.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0, 0, 0, 0.25);
}

.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
  background: #000000;
}

.swiper-pagination-lock {
  display: none;
}

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-scrollbar-lock {
  display: none;
}

.swiper-zoom-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.swiper-slide-zoomed {
  cursor: move;
}

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}
/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube {
  overflow: visible;
}

.swiper-container-cube .swiper-slide {
  pointer-events: none;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right {
  z-index: 0;
  backface-visibility: hidden;
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  filter: blur(50px);
  z-index: 0;
}

.swiper-container-flip {
  overflow: visible;
}

.swiper-container-flip .swiper-slide {
  pointer-events: none;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  backface-visibility: hidden;
}

.swiper-container-coverflow .swiper-wrapper {
  -ms-perspective: 1200px;
}

@font-face {
  font-family: "manrope";
  src: url("/fonts/manropebold.woff2") format("woff2"), url("/fonts/manropebold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "manrope";
  src: url("/fonts/manropelight.woff2") format("woff2"), url("/fonts/manropelight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "montserrat";
  src: url("/fonts/montserrat-bold.woff2") format("woff2"), url("/fonts/montserrat-bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "montserrat";
  src: url("/fonts/montserrat-semibold.woff2") format("woff2"), url("/fonts/montserrat-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "montserrat";
  src: url("/fonts/montserrat-medium.woff2") format("woff2"), url("/fonts/montserrat-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "montserrat";
  src: url("/fonts/montserrat-regular.woff2") format("woff2"), url("/fonts/montserrat-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserrat";
  src: url("/fonts/montserrat-italic.woff2") format("woff2"), url("/fonts/montserrat-italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "montserrat";
  src: url("/fonts/montserrat-light.woff2") format("woff2"), url("/fonts/montserrat-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "montserrat";
  src: url("/fonts/montserrat-lightitalic.woff2") format("woff2"), url("/fonts/montserrat-lightitalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}
body {
  min-width: 320px;
  min-height: 100%;
  margin: 0;
  font-family: "montserrat", Arial, sans-serif;
  font-size: 16px;
  color: #000000;
  line-height: 1.75;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  position: relative;
}
body.lock-scroll {
  position: fixed;
  width: 100%;
  height: 100%;
}

.t-preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 100;
  opacity: 1;
  transition: opacity 0.4s;
  pointer-events: auto;
}
.t-preloader._close {
  opacity: 0;
  pointer-events: none;
}

.load {
  opacity: 0;
  transition: opacity 0.5s 0s;
}
.load._load {
  opacity: 1;
  transition: opacity 0.6s 0s;
}

.t-animate {
  opacity: 1 !important;
  transition: all 0.5s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transform: translate3d(0, 0, 0);
}
.t-animate.hide {
  opacity: 0 !important;
  transform: translate3d(0, 50px, 0);
}
.t-animate.hide .t-animate-item {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}
.t-animate-item {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s !important;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1) !important;
}
.t-animate-item:nth-child(1) {
  transition-delay: 0.22s !important;
}
.t-animate-item:nth-child(2) {
  transition-delay: 0.34s !important;
}
.t-animate-item:nth-child(3) {
  transition-delay: 0.46s !important;
}
.t-animate-item:nth-child(4) {
  transition-delay: 0.58s !important;
}
.t-animate-item:nth-child(5) {
  transition-delay: 0.7s !important;
}
.t-animate-item:nth-child(6) {
  transition-delay: 0.82s !important;
}
.t-animate-item:nth-child(7) {
  transition-delay: 0.94s !important;
}
.t-animate-item:nth-child(8) {
  transition-delay: 1.06s !important;
}
.t-animate-item:nth-child(9) {
  transition-delay: 1.18s !important;
}
.t-animate-item:nth-child(10) {
  transition-delay: 1.3s !important;
}
.t-animate-item:nth-child(11) {
  transition-delay: 1.42s !important;
}
.t-animate-item:nth-child(12) {
  transition-delay: 1.54s !important;
}
.t-animate-item:nth-child(13) {
  transition-delay: 1.66s !important;
}
.t-animate-item:nth-child(14) {
  transition-delay: 1.78s !important;
}
.t-animate-item:nth-child(15) {
  transition-delay: 1.9s !important;
}
.t-animate-item:nth-child(16) {
  transition-delay: 2.02s !important;
}
.t-animate-item:nth-child(17) {
  transition-delay: 2.14s !important;
}
.t-animate-item:nth-child(18) {
  transition-delay: 2.26s !important;
}
.t-animate-item:nth-child(19) {
  transition-delay: 2.38s !important;
}
.t-animate-item:nth-child(20) {
  transition-delay: 2.5s !important;
}
.t-animate-item:nth-child(21) {
  transition-delay: 2.62s !important;
}
.t-animate-item:nth-child(22) {
  transition-delay: 2.74s !important;
}
.t-animate-item:nth-child(23) {
  transition-delay: 2.86s !important;
}
.t-animate-item:nth-child(24) {
  transition-delay: 2.98s !important;
}
.t-animate-item:nth-child(25) {
  transition-delay: 3.1s !important;
}
.t-animate-item:nth-child(26) {
  transition-delay: 3.22s !important;
}
.t-animate-item:nth-child(27) {
  transition-delay: 3.34s !important;
}
.t-animate-item:nth-child(28) {
  transition-delay: 3.46s !important;
}
.t-animate-item:nth-child(29) {
  transition-delay: 3.58s !important;
}
.t-animate-item:nth-child(30) {
  transition-delay: 3.7s !important;
}
.t-animate-item:nth-child(31) {
  transition-delay: 3.82s !important;
}
.t-animate-item:nth-child(32) {
  transition-delay: 3.94s !important;
}
.t-animate-item:nth-child(33) {
  transition-delay: 4.06s !important;
}
.t-animate-item:nth-child(34) {
  transition-delay: 4.18s !important;
}
.t-animate-item:nth-child(35) {
  transition-delay: 4.3s !important;
}
.t-animate-item:nth-child(36) {
  transition-delay: 4.42s !important;
}
.t-animate-item:nth-child(37) {
  transition-delay: 4.54s !important;
}
.t-animate-item:nth-child(38) {
  transition-delay: 4.66s !important;
}
.t-animate-item:nth-child(39) {
  transition-delay: 4.78s !important;
}
.t-animate-item:nth-child(40) {
  transition-delay: 4.9s !important;
}
.t-animate-item:nth-child(41) {
  transition-delay: 5.02s !important;
}
.t-animate-item:nth-child(42) {
  transition-delay: 5.14s !important;
}
.t-animate-item:nth-child(43) {
  transition-delay: 5.26s !important;
}
.t-animate-item:nth-child(44) {
  transition-delay: 5.38s !important;
}
.t-animate-item:nth-child(45) {
  transition-delay: 5.5s !important;
}
.t-animate-item:nth-child(46) {
  transition-delay: 5.62s !important;
}
.t-animate-item:nth-child(47) {
  transition-delay: 5.74s !important;
}
.t-animate-item:nth-child(48) {
  transition-delay: 5.86s !important;
}
.t-animate-item:nth-child(49) {
  transition-delay: 5.98s !important;
}
.t-animate-item:nth-child(50) {
  transition-delay: 6.1s !important;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: #000000;
  text-decoration: none;
}
a:not([class]) {
  color: #41a6e4;
}
a:not([class]):hover, a:not([class]):active {
  color: #00beff;
}

h1, h2, h3 {
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

h1 {
  font-size: 56px;
  line-height: 1.43;
}

h2 {
  font-size: 52px;
  line-height: 1.54;
  font-weight: 700;
}

h3 {
  font-size: 48px;
  line-height: 1.67;
  font-weight: 700;
}

.t-work-area {
  padding: 0 80px 0 166px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

.t-work-area-fullRight {
  padding: 0 0 0 166px;
  margin: 0 0 0 calc((100% - 1440px) / 2) !important;
  max-width: calc((100% - 1440px) / 2 + 1440px);
}

.t-work-area-marginLeft {
  padding: 0 30px 0 424px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

.t-text {
  font-size: 16px;
  white-space: pre-line;
}
.t-text_big {
  font-size: 26px;
  line-height: 1.62;
  white-space: pre-line;
}
.t-text_small {
  font-size: 14px;
  line-height: 2;
  white-space: pre-line;
}

.t-background {
  background: radial-gradient(circle, rgba(0, 0, 0, 0.06) 0.0753em, transparent 0.09789em) 0 0, radial-gradient(circle, rgba(0, 0, 0, 0.06) 0.0753em, transparent 0.09789em) 0.251em 0.251em;
  background-size: 0.502em 0.502em;
  background-color: transparent;
  background-repeat: repeat;
}

.t-btn {
  overflow: hidden;
  padding: 19px 28px;
  border-radius: 10px 10px 10px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  font-family: "montserrat", Arial, sans-serif;
  box-shadow: 0 35px 25px -25px rgba(163, 182, 184, 0.4);
  transition: all 0.3s !important;
  z-index: 1;
}
.t-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc(100% + 80px);
  transform: translateX(-80px);
  transition: transform 0.3s ease-in;
  z-index: -1;
}
.t-btn span {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}
.t-btn._img {
  padding: 29px 78px 9px 15px;
}
.t-btn_img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% 0;
}
.t-btn_number {
  position: absolute;
  right: 11px;
  bottom: 10px;
  opacity: 0.4;
  font-size: 16px !important;
  line-height: 1.5;
}
.t-btn._green:before {
  background: linear-gradient(251deg, #00db00, #00b500);
}
.t-btn._green .t-btn_img {
  background-image: url("/img/ico-it-btn.png");
}
.t-btn._blue:before {
  background: linear-gradient(256deg, #13e0e0, #08bdbd);
}
.t-btn._blue .t-btn_img {
  background-image: url("/img/ico-fin-btn.png");
}
.t-btn_arrow {
  display: none;
  min-width: 17px;
  margin-left: 10px;
}
@media (max-width: 767px) {
  .t-btn_arrow._mobile {
    display: flex;
  }
}
.t-btn:active {
  transform: translateY(2px) !important;
  box-shadow: 0 35px 20px -25px rgba(163, 182, 184, 0.5) !important;
}
@media (min-width: 1024px) {
  .t-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 35px 30px -20px rgba(163, 182, 184, 0.4);
  }
  .t-btn:hover:before {
    transform: translateX(0);
  }
}

.t-title {
  position: relative;
  width: fit-content;
}
.t-title img {
  position: absolute;
  top: -40px;
  right: -140px;
}
.t-title_mobile {
  display: none;
}

.t-column {
  display: flex;
  flex-direction: column;
}
.t-column__item {
  margin-bottom: 30px;
  padding: 36px 25px 36px 42px;
  max-width: 845px;
  border: 1px solid rgba(189, 207, 209, 0.5);
  border-radius: 15px 45px 15px 15px;
  background-color: #ffffff;
}
.t-column__item:nth-child(even) {
  margin-left: 40px;
}
.t-column__item.t-shadow:after {
  top: 65px;
  left: 23px;
  width: calc(100% - 46px);
  height: calc(100% - 50px);
}
.t-column__head {
  margin-bottom: 28px;
  display: flex;
  align-items: flex-start;
}
.t-column__head img {
  margin-top: 5px;
}
.t-column__title {
  margin-left: 17px;
  font-size: 23px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
}
.t-column__text {
  font-size: 18px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.89;
}

.t-shadow {
  box-shadow: 0 30px 30px -25px rgba(163, 182, 184, 0.4);
  transition: box-shadow 0.4s;
}
.t-shadow:active {
  box-shadow: 0 28px 25px -22px rgba(163, 182, 184, 0.5) !important;
}
@media (min-width: 1024px) {
  .t-shadow:hover {
    box-shadow: 0 32px 35px -25px rgba(163, 182, 184, 0.4);
  }
}

.t-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.t-block__item {
  border: 1px solid rgba(189, 207, 209, 0.5);
  border-radius: 16px;
  background-color: #ffffff;
  margin: 0 30px 40px 0;
}
.t-block__item:nth-child(1), .t-block__item:nth-child(5), .t-block__item:nth-child(6) {
  padding: 37px 32px 40px 41px;
  font-size: 21px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.48;
}
.t-block__item:not(:nth-child(1), :nth-child(5), :nth-child(6)) {
  padding: 22px 24px 14px;
  font-size: 14px;
  line-height: 1.43;
}
.t-block__item:nth-child(1) {
  max-width: 281px;
}
.t-block__item:nth-child(2) {
  max-width: 241px;
}
.t-block__item:nth-child(3) {
  max-width: 427px;
}
.t-block__item:nth-child(4) {
  max-width: 452px;
}
.t-block__item:nth-child(5) {
  max-width: 583px;
}
.t-block__item:nth-child(6) {
  max-width: 555px;
}
.t-block__item:nth-child(7) {
  max-width: 290px;
}
.t-block__item:nth-child(8) {
  max-width: 427px;
}
.t-block__item:nth-child(9) {
  max-width: 316px;
}
.t-block__item:nth-child(10) {
  max-width: 284px;
}
.t-block__item.t-shadow:after {
  width: calc(100% - 46px);
  height: calc(100% - 16px);
  bottom: -10px;
  left: 23px;
}
.t-block._vacancies .t-block__item {
  margin: 0 25px 38px 0;
}
.t-block._vacancies .t-block__item:nth-child(1), .t-block._vacancies .t-block__item:nth-child(5), .t-block._vacancies .t-block__item:nth-child(8) {
  padding: 37px 32px 40px 41px;
  font-size: 21px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.48;
}
.t-block._vacancies .t-block__item:not(:nth-child(1), :nth-child(5), :nth-child(8)) {
  padding: 22px 24px 14px;
  font-size: 14px;
  line-height: 1.43;
}
.t-block._vacancies .t-block__item:nth-child(1) {
  max-width: 281px;
}
.t-block._vacancies .t-block__item:nth-child(2) {
  max-width: 452px;
}
.t-block._vacancies .t-block__item:nth-child(3) {
  max-width: 427px;
}
.t-block._vacancies .t-block__item:nth-child(4) {
  max-width: 290px;
}
.t-block._vacancies .t-block__item:nth-child(5) {
  max-width: 560px;
}
.t-block._vacancies .t-block__item:nth-child(6) {
  max-width: 280px;
  margin-right: 0;
}
.t-block._vacancies .t-block__item:nth-child(7) {
  max-width: 241px;
}
.t-block._vacancies .t-block__item:nth-child(8) {
  max-width: 555px;
}
.t-block._vacancies .t-block__item:nth-child(9) {
  max-width: 427px;
}
.t-block._vacancies .t-block__item:nth-child(10) {
  max-width: 316px;
}

._hidden {
  height: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  margin: 0 !important;
  opacity: 0;
}

.t-container-order {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.marquee {
  -webkit-animation: animMarquee 15s linear infinite;
  animation: animMarquee 15s linear infinite;
}

@keyframes animMarquee {
  0% {
    -webkit-transform: translateX(calc(100% - 160px));
    transform: translateX(calc(100% - 160px));
  }
  100% {
    -webkit-transform: translateX(calc(-100% - 170px));
    transform: translateX(calc(-100% - 170px));
  }
}
.t-arrow {
  width: 36px;
  height: 36px;
  background-color: #bdcfd1;
  border-radius: 50%;
  position: relative;
  background-image: none !important;
  opacity: 1 !important;
  transform: rotate(180deg);
}
.t-arrow span {
  position: absolute;
  top: 13px;
  left: 13px;
  width: 13px;
  height: 3px;
  background-color: #ffffff;
  border-radius: 20px;
  transform: rotate(45deg);
}
.t-arrow span:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 13px;
  height: 3px;
  background-color: #ffffff;
  border-radius: 20px;
  transform: rotate(90deg);
}

.t-one {
  position: absolute;
  top: 0;
  right: 0;
  width: 5000px;
  height: 100%;
  padding: 0;
  z-index: -3;
  filter: grayscale(0);
  opacity: 1;
  transform: translate3d(100%, -205px, 0) scale3d(1, 1, 1);
  -moz-transform: translate3d(100%, -205px, 0) scale3d(1, 1, 1);
  transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  transition: transform 0.8s, filter 0.8s, opacity 0.8s;
  -moz-transition: transform 0.8s, filter 0.8s, opacity 0.8s;
}
.t-one__top {
  height: 204px;
  border-bottom: 204px solid #00d300;
  border-left: 204px solid transparent;
}
.t-one__bottom {
  margin-left: 172px;
  width: calc(100% - 172px);
  height: calc(100% - 204px);
  background: linear-gradient(174deg, #00d300, #0CD3A9);
}
.t-one._animate-one {
  transform: translate3d(calc(100% - 337px - (100vw - 1440px) / 2), 0, 0) scale3d(1, 1, 1);
  -moz-transform: translate3d(calc(100% - 337px - (100vw - 1440px) / 2), 0, 0) scale3d(1, 1, 1);
}
.t-one._animate-gray {
  transform: translate3d(calc(15324px - (100vw - 1440px) / 2), 0, 0) scale3d(3.4, 3.4, 1);
  -moz-transform: translate3d(calc(15324px - (100vw - 1440px) / 2), 0, 0) scale3d(3.4, 3.4, 1);
  filter: grayscale(1);
  opacity: 0.13;
  transition: transform 0.8s, filter 0.3s, opacity 0.3s;
  -moz-transition: transform 0.8s, filter 0.3s, opacity 0.3s;
}
.t-one._animate-hidden {
  transition: transform 0.8s, filter 0.3s, opacity 0.3s;
  -moz-transition: transform 0.8s, filter 0.3s, opacity 0.3s;
  transform: translate3d(calc(15324px - (100vw - 1440px) / 2), 0, 0) scale3d(3.7, 4.7, 1);
  -moz-transform: translate3d(calc(15324px - (100vw - 1440px) / 2), 0, 0) scale3d(3.7, 4.7, 1);
  opacity: 0;
}
.t-one._animate-disable {
  transition: all 0s;
  -moz-transition: all 0s;
}
.t-one-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -20;
}

@media (max-width: 1440px) {
  .t-work-area-fullRight {
    margin: 0 !important;
  }
  .t-one._animate-one {
    transform: translate3d(calc(100% - 337px), 0, 0) scale3d(1, 1, 1);
  }
  .t-one._animate-gray {
    transform: translate3d(calc(15324px + 1440px - 100vw), 0, 0) scale3d(3.4, 3.4, 1);
  }
}
@media (max-width: 1400px) {
  .t-work-area-marginLeft {
    padding: 0 30px 0 320px;
  }
  .t-one._animate-gray {
    transform: translate3d(calc(15255px + 1440px - 100vw), 0, 0) scale3d(3.4, 3.4, 1);
  }
}
@media (max-width: 1320px) {
  .t-work-area {
    padding: 0 40px 0 80px;
  }
  .t-work-area-fullRight {
    padding: 0 0 0 80px;
  }
  h1 {
    font-size: 52px;
  }
  h2 {
    font-size: 38px;
  }
  h3 {
    font-size: 36px;
  }
  .t-text_big {
    font-size: 24px;
  }
  .t-title img {
    top: -30px;
    right: -105px;
  }
  .t-block._vacancies .t-block__item:nth-child(5) {
    max-width: 535px;
  }
  .t-block._vacancies .t-block__item:nth-child(8) {
    max-width: 540px;
  }
}
@media (max-width: 1200px) {
  .t-block._vacancies .t-block__item:nth-child(6) {
    margin-right: 25px;
  }
}
@media (max-width: 1000px) {
  .t-work-area {
    padding: 0 40px;
  }
  .t-work-area-fullRight {
    padding: 0 0 0 40px;
  }
  .t-work-area-marginLeft {
    padding: 0 30px 0 40px;
  }
  h1 {
    font-size: 36px;
    line-height: normal;
  }
  h2 {
    font-size: 32px;
    line-height: normal;
  }
  h3 {
    font-size: 30px;
    line-height: normal;
  }
  .t-text_big {
    font-size: 20px;
  }
  .t-one {
    transform: translate3d(100%, -75px, 0) scale3d(1, 1, 1);
  }
  .t-one__top {
    height: 90px;
    border-bottom: 90px solid #00d300;
    border-left: 90px solid transparent;
  }
  .t-one__bottom {
    margin-left: 40px;
    width: calc(100% - 40px);
    height: calc(100% - 90px);
  }
  .t-one._animate-one {
    transform: translate3d(calc(100% - 77px), 0, 0) scale3d(1, 1, 1);
  }
  .t-one._animate-gray, .t-one._animate-hidden, .t-one.js-one-2, .t-one.js-one-3 {
    transform: translate3d(100%, -75px, 0) scale3d(1, 1, 1);
  }
  .t-title_mobile {
    display: block;
  }
  .t-title_hidden-mobile {
    display: none;
  }
}
@media (max-width: 767px) {
  body {
    font-size: 14px;
  }
  h3 {
    font-size: 18px;
    line-height: 31px;
  }
  .t-work-area {
    padding: 0 16px;
  }
  .t-work-area-fullRight {
    padding: 0 0 0 16px;
  }
  .t-work-area-marginLeft {
    padding: 0 16px;
  }
  .t-background {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.06) 0.09em, transparent 0.117em) 0 0, radial-gradient(circle, rgba(0, 0, 0, 0.06) 0.09em, transparent 0.117em) 0.3em 0.3em;
    background-size: 0.6em 0.6em;
    background-color: transparent;
    background-repeat: repeat;
  }
  .t-title {
    max-width: 222px;
    font-weight: 600;
  }
  .t-title img {
    transform: scale(0.65);
    top: -40px;
    right: -80px;
  }
  .t-btn {
    padding: 14px 10px;
  }
  .t-btn span {
    font-size: 14px;
  }
  .t-text_big {
    font-size: 16px;
  }
  .t-column__item {
    margin-bottom: 10px;
    padding: 20px 18px 30px;
  }
  .t-column__item:nth-child(even) {
    margin-left: 0;
  }
  .t-column__head {
    margin-bottom: 15px;
  }
  .t-column__head img {
    width: 10px;
    height: 16px;
    object-fit: contain;
  }
  .t-column__title {
    margin-left: 8px;
    font-size: 16px;
  }
  .t-column__text {
    font-size: 14px;
  }
  .t-block__item {
    margin: 0 0 10px;
    width: 100% !important;
    max-width: 100% !important;
    padding: 20px 18px !important;
    font-size: 14px !important;
    font-family: "manrope", Arial, sans-serif;
    font-weight: 300;
  }
  .t-block._vacancies .t-block__item {
    margin: 0 0 10px 0;
    font-family: "manrope", Arial, sans-serif;
    font-weight: 300;
  }
  .t-block._vacancies .t-block__item:nth-child(6) {
    margin-right: 0;
  }
  ._hidden {
    width: auto !important;
  }
  .t-container-order {
    overflow: hidden;
  }
}
header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
header._main .t-logo_main {
  display: block !important;
  min-height: 80px;
}
header._main .t-logo_not-main {
  display: none;
}

.t-header {
  padding: 55px 79px;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.t-header::-webkit-scrollbar {
  display: none;
  width: 0;
}

.t-logo {
  width: 138px;
  margin-right: 97px;
  z-index: 1;
}
.t-logo._hidden {
  width: 0;
}
.t-logo a {
  display: block;
  transition: transform 0.3s;
}
.t-logo a:active {
  transform: scale(0.97);
}
.t-logo svg {
  display: block;
  width: 100%;
  height: 100%;
}
.t-logo svg g {
  transition: fill 0.3s;
}
.t-logo:active svg g {
  fill: #00b500;
}
@media (min-width: 1024px) {
  .t-logo:hover svg g {
    fill: #00b500;
  }
}
.t-logo_main {
  display: none !important;
}

._logo-mobile {
  display: none;
  min-height: 47px;
}
@media (max-width: 767px) {
  ._logo-mobile {
    display: block;
  }
  ._logo-mobile_hidden {
    display: none;
  }
}

.t-nav {
  margin-left: 107px;
}
.t-nav ul {
  display: flex;
  align-items: center;
  list-style-type: none;
}
.t-nav__link {
  margin-right: 48px;
  position: relative;
  color: #a3b6b8;
  font-weight: 400;
  white-space: nowrap;
  display: block;
  transition: color 0.3s, transform 0.3s;
}
.t-nav__link._active {
  color: #000000;
  pointer-events: none;
}
.t-nav__link._active:after {
  content: "";
  position: absolute;
  top: 5px;
  left: -26px;
  width: 21px;
  height: 15px;
  clip-path: polygon(0 100%, 69.7% 0, 100% 0, 30.3% 100%);
  -webkit-clip-path: polygon(0 100%, 69.7% 0, 100% 0, 30.3% 100%);
}
.t-nav__link._vacancies:after {
  background: linear-gradient(126deg, #00d300, #0fd3d3);
}
.t-nav__link._it-vacancies:after {
  background: linear-gradient(55deg, #00d300, #0fd3d3);
}
.t-nav__link._banking-vacancies:after {
  background: linear-gradient(234deg, #13e0e0, #08bdbd);
}
.t-nav__link:active {
  transform: scale(0.93);
}
@media (min-width: 1024px) {
  .t-nav__link:hover {
    color: #000000;
  }
}

.t-burger {
  display: none;
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 20px;
  right: 16px;
  z-index: 20;
}
.t-burger._open span {
  background-color: #ffffff;
}
.t-burger._open span:before {
  transform: rotate(135deg) translateY(0);
}
.t-burger._open span:after {
  transform: rotate(-135deg) translateY(0);
}
.t-burger span {
  position: relative;
  width: 14px;
  height: 2px;
  background-color: #00d800;
  transition: background-color 0s;
}
.t-burger span:before, .t-burger span:after {
  content: "";
  position: absolute;
  width: 14px;
  height: 2px;
  background-color: #00d800;
  transition: transform 0.3s;
}
.t-burger span:before {
  transform: translateY(-4px);
}
.t-burger span:after {
  transform: translateY(4px);
}
.t-burger-content {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #F0F0F0;
  padding-top: 48px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
}
@media (max-width: 920px) {
  .t-burger-content {
    padding-top: 97px;
  }
}
.t-burger-content._open {
  opacity: 1;
  pointer-events: auto;
}
.t-burger-content__container {
  display: flex;
}
.t-burger-content__info {
  width: 50%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  border: 1px solid rgba(189, 207, 209, 0.5);
}
.t-burger-content__info:first-child {
  border-right: transparent;
}
.t-burger-content__block {
  padding: 30px 56px;
  justify-content: center;
  background-color: #ffffff;
}
.t-burger-content__address {
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}
.t-burger-content__time {
  color: #78979a;
  padding: 0 20px;
  text-align: center;
}
.t-burger-content__bottom {
  padding: 14px 0 16px;
  display: flex;
  justify-content: center;
  color: #bdcfd1;
  font-size: 12px;
}

.t-nav-mobile {
  padding: 0 16px;
  margin-bottom: 24px;
}
.t-nav-mobile ul {
  list-style-type: none;
}
.t-nav-mobile ul li {
  display: flex;
  align-items: center;
}
.t-nav-mobile__link {
  padding: 16px 0;
  font-size: 22px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.2;
}
.t-nav-mobile__link._active {
  background: linear-gradient(102deg, #00d300, #0fd3d3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 1320px) {
  .t-nav {
    margin-left: 5px;
  }
}
@media (max-width: 1000px) {
  header:not(._main) .t-logo {
    display: none;
  }
  .t-header {
    padding: 30px 40px;
  }
}
@media (max-width: 767px) {
  header {
    z-index: 20;
  }
  header:not(._main) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-x;
    background-color: #ffffff;
  }
  header:not(._main)::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  header:not(._main) .t-header {
    padding: 10px 16px;
    width: fit-content;
    min-width: 100%;
    background-color: rgba(189, 207, 209, 0.2);
  }
  .t-header {
    padding: 20px 16px;
  }
  .t-nav {
    margin-left: 20px;
  }
  .t-nav__link {
    margin-right: 40px;
  }
  .t-nav__link._active:after {
    left: -20px;
    width: 16px;
    height: 13px;
  }
  .t-logo {
    width: 100px;
    min-width: 100px;
    margin-right: 0;
    z-index: 0;
  }
  .t-logo._hidden {
    min-width: 0;
    width: 0 !important;
  }
  .t-burger._visible {
    display: flex;
  }
}
.t-main {
  padding-top: 296px;
}
.t-main .t-btn {
  color: #fff !important;
  font-weight: 600;
}
.t-main-top {
  width: 100%;
  height: 361px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.t-main-slider {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 841px;
  max-height: 100%;
  clip-path: polygon(0 100%, 42.7% 0, 100% 0, 57.3% 100%);
  -webkit-clip-path: polygon(0 100%, 42.7% 0, 100% 0, 57.3% 100%);
}
.t-main-slider .swiper-container {
  height: 361px;
  cursor: grab;
}
.t-main-slider__pattern {
  width: 434px;
  height: 361px;
  position: absolute;
  top: 0;
  right: -76px;
  clip-path: polygon(0 100%, 82.7% 0, 100% 0, 17.3% 100%);
  -webkit-clip-path: polygon(0 100%, 82.7% 0, 100% 0, 17.3% 100%);
}
.t-main-slider img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  clip-path: polygon(0 100%, 42.7% 0, 100% 0, 57.3% 100%);
  -webkit-clip-path: polygon(0 100%, 42.7% 0, 100% 0, 57.3% 100%);
}
.t-main-swiper-pagination {
  margin-top: -6px;
  height: 6px;
  width: 500px;
}
.t-main-swiper-pagination .swiper-pagination-progressbar-fill {
  background: linear-gradient(251deg, #00db00, #00b500) !important;
  clip-path: polygon(0 100%, 0 0, 100% 0, 95.5% 100%);
  -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 95.5% 100%);
}
.t-main-container {
  max-width: 815px;
  margin-bottom: 188px;
}
.t-main-container h1 {
  margin-bottom: 45px;
}
.t-main-container .t-title img {
  top: -12px;
  right: -40px;
}
@media (max-width: 920px) {
  .t-main-container .t-main-cupis__text {
    font-size: 10px;
  }
}
.t-main-btn {
  margin-top: 59px;
  margin-bottom: 152px;
  display: flex;
}
.t-main-btn__left {
  margin-right: 20px;
}
.t-main-btn .t-btn {
  padding: 19px 28px;
  justify-content: center;
  line-height: 22px;
}
@media (max-width: 920px) {
  .t-main-btn .t-btn {
    padding: 14px 0;
  }
  .t-main-btn ._blue {
    width: 158px;
  }
  .t-main-btn ._green {
    width: 118px;
  }
}
.t-main-cupis {
  display: flex;
  align-items: flex-start;
  position: relative;
}
.t-main-cupis__question {
  margin-top: 2px;
  margin-right: 11px;
}
.t-main-cupis__text {
  max-width: 304px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.58;
  color: #a3b6b8;
}
@media (max-width: 920px) {
  .t-main-cupis__text {
    color: #78979A;
    font-weight: 500;
  }
}
.t-main-cupis .t-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
@media (max-width: 920px) {
  .t-main-cupis .t-background {
    display: block;
  }
}
.t-main-it {
  position: relative;
}
.t-main-it__container {
  margin-left: 74px;
  max-width: 587px;
  margin-top: 14px;
}
.t-main-it__text {
  margin-bottom: 40px;
  white-space: normal !important;
}
@media (max-width: 920px) {
  .t-main-it__text {
    font-size: 13px;
    margin-bottom: 0;
  }
}
.t-main-it__img {
  width: 191px;
  height: 191px;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 130px;
}
.t-main-it__img-1 {
  position: absolute;
  top: -115px;
  left: -5px;
}
.t-main-it__img-2 {
  position: absolute;
  top: -55px;
  right: 40px;
}
.t-main-it__btn {
  margin-bottom: 188px;
}
.t-main-bank {
  padding-left: 448px;
  position: relative;
}
.t-main-bank__img {
  width: 191px;
  height: 191px;
  border-radius: 50%;
  position: absolute;
  top: 157px;
  left: 48px;
}
.t-main-bank__img-1, .t-main-bank__img-2, .t-main-bank__img-3, .t-main-bank__img-4 {
  position: absolute;
  bottom: -19px;
  width: 20px;
  background: linear-gradient(2deg, #00d300, #0fd3d3);
}
.t-main-bank__img-1 {
  left: -10px;
  height: 56px;
}
.t-main-bank__img-2 {
  left: 36px;
  height: 97px;
}
.t-main-bank__img-3 {
  left: 82px;
  height: 166px;
}
.t-main-bank__img-4 {
  left: 128px;
  height: 233px;
}
.t-main-bank__img-2:after, .t-main-bank__img-4:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.16);
}
.t-main-bank__btn {
  margin-left: 448px;
  margin-bottom: 80px;
  white-space: nowrap;
}
.t-main-bank__btn span {
  white-space: nowrap;
}
.t-main-quote {
  position: relative;
}
.t-main-quote__container {
  margin-bottom: 100px;
  padding-left: 448px;
}
.t-main-quote__title {
  margin-bottom: 38px;
  display: flex;
  align-items: center;
}
.t-main-quote__face {
  width: 112px;
  height: 113px;
  margin-right: 40px;
}
.t-main-quote__face img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.t-main-quote__name {
  font-size: 23px;
  line-height: 1.35;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
}
.t-main-quote__position {
  font-size: 14px;
  line-height: 2;
  color: #78979a;
}
.t-main-quote__text {
  padding-left: 155px;
  padding-right: 5px;
  white-space: pre-line;
  font-style: italic;
  color: #5a878b;
  line-height: 1.88;
}
.t-main-quote__line {
  position: absolute;
  top: 180px;
  right: calc(100% - (100% - 1440px) / 2 - 110px - 620px);
  height: 32px;
  width: calc(100% - (100% - (100% - 1440px) / 2 - 110px - 620px));
  background: url("/img/wave.png") no-repeat;
}
.t-main-quote__line._bottom {
  top: auto;
  bottom: 60px;
}
.t-main-block {
  margin-top: 60px;
  padding-top: 67px;
  position: relative;
  order: 9;
}
.t-main-block .t-background {
  height: calc(100% - 188px);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.t-main-block__title {
  margin: 0 0 22px;
  font-size: 18px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
  line-height: 4.67;
  letter-spacing: 7.2px;
  text-align: center;
  text-transform: uppercase;
}
.t-main-block__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 764px;
  margin: 0 auto 150px;
}
.t-main-block__item {
  margin-bottom: 19px;
  padding: 19px;
  width: 241px;
  border: 1px solid rgba(189, 207, 209, 0.5);
  border-radius: 30px 10px 10px 10px;
  background-color: #ffffff;
  cursor: pointer;
  transition: transform 0.3s;
}
.t-main-block__item:not(:nth-child(3n)) {
  margin-right: 20px;
}
.t-main-block__item:last-child {
  margin-right: 0;
}
.t-main-block__item.t-shadow:after {
  top: 18px;
  left: 14px;
  height: calc(100% - 13px);
  width: calc(100% - 28px);
}
.t-main-block__item img {
  margin: -16px 0 0 -28px;
  max-width: 99px;
  max-height: 96px;
  pointer-events: none;
}
.t-main-block__item._all {
  display: flex;
  align-items: center;
  height: 91px;
}
.t-main-block__item._all img {
  margin: 7px 0 0 -28px;
}
.t-main-block__item._all .t-main-block__subtitle {
  margin-top: 0;
}
.t-main-block__item:active {
  transform: translateY(2px) !important;
}
@media (min-width: 1024px) {
  .t-main-block__item:hover {
    transform: translateY(-2px);
  }
}
.t-main-block__subtitle {
  margin-top: -16px;
  font-size: 18px;
  font-weight: bold;
}
.t-main-company {
  padding: 120px 0 0;
  overflow: hidden;
  transition: opacity 0.7s;
}
.t-main-company .t-main-cupis {
  max-width: 290px;
  margin-bottom: 32px;
  margin-left: auto;
}
.t-main-company .t-main-company-swiper2 {
  margin-bottom: 280px;
}
@media (min-width: 1024px) {
  .t-main-company .t-main-company-swiper2 {
    margin-left: 85px;
  }
}
.t-main-company__text {
  margin: 36px 0 35px 86px;
  max-width: 675px;
  font-weight: 300;
}
.t-main-company-swiper {
  padding-left: 252px;
  padding-bottom: 40px;
  cursor: grab;
  width: 100%;
  max-width: calc(100% - (100% - 1440px) / 2);
  overflow: visible !important;
  margin: 0 0 0 calc((100% - 1440px) / 2);
  display: flex;
}
.t-main-company-swiper .swiper-slide:last-child {
  margin-right: 166px !important;
}
.t-main-company-swiper .swiper-wrapper {
  width: fit-content !important;
}
.t-main-company-slider {
  position: relative;
  margin-bottom: 130px;
}
.t-main-company-slider .t-background {
  position: absolute;
  top: calc(50% - 65px);
  left: 0;
  height: 130px;
  width: 100%;
  z-index: -1;
}
.t-main-company-slider__item {
  width: 100%;
  max-width: 100%;
  padding: 20px 20px 40px 40px;
  border: 1px solid rgba(189, 207, 209, 0.5);
  border-radius: 15px 30px 15px 15px;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}
.t-main-company-slider__item:hover .t-main-company-slider__line {
  opacity: 1;
  transition: opacity 0.3s;
}
.t-main-company-slider__item:hover .t-main-company-slider__line:after {
  transform: translateX(0);
}
.t-main-company-slider__container {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.t-main-company-slider__container span {
  background: linear-gradient(67deg, #00d300, #0fd3d3);
  font-size: 21px;
  line-height: 1.48;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.t-main-company-slider__number {
  width: 40px;
  height: 40px;
  background-color: rgba(189, 207, 209, 0.26);
  color: #a3b6b8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.t-main-company-slider__text {
  font-size: 21px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.48;
}
.t-main-company-slider__line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 7px;
  background-color: rgba(189, 207, 209, 0.26);
  opacity: 0;
  transition: opacity 0.8s;
}
.t-main-company-slider__line:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.7s;
  width: calc(100% + 10px);
  height: 7px;
  background: linear-gradient(269deg, #00db00, #00b500);
  clip-path: polygon(0 100%, 0 0, 100% 0, 97.5% 100%);
  -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 97.5% 100%);
}
.t-main-company-slider .t-swiper-nav {
  position: absolute;
  bottom: -115px;
  right: 80px;
  height: 35px;
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.t-main-company-slider .swiper-button-prev, .t-main-company-slider .swiper-button-next {
  outline: none;
  transition: transform 0.3s;
}
.t-main-company-slider .swiper-button-prev.swiper-button-disabled, .t-main-company-slider .swiper-button-next.swiper-button-disabled {
  background-color: transparent;
  opacity: 1 !important;
}
.t-main-company-slider .swiper-button-prev.swiper-button-disabled span, .t-main-company-slider .swiper-button-prev.swiper-button-disabled span:after, .t-main-company-slider .swiper-button-next.swiper-button-disabled span, .t-main-company-slider .swiper-button-next.swiper-button-disabled span:after {
  background-color: #bdcfd1;
}
.t-main-company-slider .swiper-button-prev:active {
  transform: scale(0.95) rotate(180deg) !important;
}
@media (min-width: 1024px) {
  .t-main-company-slider .swiper-button-prev:hover {
    transform: scale(1.1) rotate(180deg);
  }
}
.t-main-company-slider .swiper-button-next {
  transform: rotate(360deg);
}
.t-main-company-slider .swiper-button-next:active {
  transform: scale(0.9) rotate(360deg) !important;
}
@media (min-width: 1024px) {
  .t-main-company-slider .swiper-button-next:hover {
    transform: scale(1.1) rotate(360deg);
  }
}
.t-main-company__quote {
  padding-top: 100px;
}
.t-main-company-swiper2 {
  padding: 30px 0 0 86px;
  margin-bottom: 255px;
  position: relative;
}
.t-main-company-swiper2 .t-swiper-nav {
  position: absolute;
  bottom: -115px;
  right: 80px;
  height: 35px;
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.t-main-company-swiper2 .swiper-button-prev, .t-main-company-swiper2 .swiper-button-next {
  outline: none;
  transition: transform 0.3s;
}
.t-main-company-swiper2 .swiper-button-prev.swiper-button-disabled, .t-main-company-swiper2 .swiper-button-next.swiper-button-disabled {
  background-color: transparent;
  opacity: 1 !important;
}
.t-main-company-swiper2 .swiper-button-prev.swiper-button-disabled span, .t-main-company-swiper2 .swiper-button-prev.swiper-button-disabled span:after, .t-main-company-swiper2 .swiper-button-next.swiper-button-disabled span, .t-main-company-swiper2 .swiper-button-next.swiper-button-disabled span:after {
  background-color: #bdcfd1;
}
.t-main-company-swiper2 .swiper-button-prev:active {
  transform: scale(0.95) rotate(180deg) !important;
}
@media (min-width: 1024px) {
  .t-main-company-swiper2 .swiper-button-prev:hover {
    transform: scale(1.1) rotate(180deg);
  }
}
.t-main-company-swiper2 .swiper-button-next {
  transform: rotate(360deg);
}
.t-main-company-swiper2 .swiper-button-next:active {
  transform: scale(0.9) rotate(360deg) !important;
}
@media (min-width: 1024px) {
  .t-main-company-swiper2 .swiper-button-next:hover {
    transform: scale(1.1) rotate(360deg);
  }
}
.t-main-company-swiper2__container {
  position: relative;
  cursor: grab;
}
.t-main-company-swiper2__img, .t-main-company-swiper2__img2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 27px;
  height: 20px;
  background: linear-gradient(54deg, #00d300, #0fd3d3);
  clip-path: polygon(0 100%, 69.7% 0, 100% 0, 30.3% 100%);
  -webkit-clip-path: polygon(0 100%, 69.7% 0, 100% 0, 30.3% 100%);
}
.t-main-company-swiper2__img2 {
  left: 16px;
}
.t-main-company-swiper2__name {
  font-size: 23px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.35;
}
.t-main-company-swiper2__position {
  font-size: 14px;
}
.t-main-company-swiper2__text {
  margin-top: 20px;
  font-size: 18px;
  line-height: 1.89;
  color: #5a878b;
  max-width: 478px;
}
.t-main-company-swiper2__pagination {
  margin-top: 22px;
  display: none;
  justify-content: flex-start;
}
.t-main-company-swiper2__pagination .swiper-pagination-bullet {
  bottom: -25px;
  width: 27px;
  height: 6px;
  clip-path: polygon(0 100%, 16.5% 0, 100% 0, 83.5% 100%);
  -webkit-clip-path: polygon(0 100%, 16.5% 0, 100% 0, 83.5% 100%);
  background-color: #bdcfd1;
  border-radius: 0 !important;
  margin-right: 5px;
  transition: all 0.3s;
}
.t-main-company-swiper2__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 57px;
  background: linear-gradient(84deg, #00d300, #0fd3d3);
  clip-path: polygon(0 100%, 7.5% 0, 100% 0, 92.5% 100%);
  -webkit-clip-path: polygon(0 100%, 7.5% 0, 100% 0, 92.5% 100%);
}
.t-main-str {
  padding: 38px 0 35px;
  height: 102px;
  overflow: hidden;
}
.t-main-str__content {
  font-size: 21px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.36;
  text-transform: uppercase;
  white-space: nowrap;
  display: flex;
  width: fit-content;
}
.t-main-str__content p {
  margin-right: 40px;
}
.t-main-product {
  padding-top: 170px;
  opacity: 0;
  transition: opacity 0.7s;
}
.t-main-product__block {
  padding: 36px 40px 70px;
  max-width: 655px;
  border: 1px solid rgba(189, 207, 209, 0.5);
  border-radius: 15px 30px 15px 15px;
}
.t-main-product__subtitle, .t-main-product__text {
  font-size: 21px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.48;
}
.t-main-product__subtitle {
  margin-bottom: 27px;
  color: #bdcfd1;
}
.t-main-product__img {
  margin-top: 20px;
  margin-bottom: 140px;
  position: relative;
  overflow: hidden;
}
.t-main-product__img .t-background {
  height: 290px;
  width: 100%;
  position: absolute;
  top: calc(50% - 145px);
  left: 0;
  z-index: -1;
}
.t-main-product__container {
  display: flex;
  align-items: flex-end;
  max-height: 732px;
}
.t-main-product__container img {
  margin-right: 20px;
  max-height: 732px;
  object-fit: contain;
}
.t-main-product__container.t-work-area-fullRight {
  display: grid;
  grid-template-columns: 20% 20% calc(60% - 40px);
}
.t-main-product__container.t-work-area-fullRight img {
  max-width: 100%;
}
.t-main-it-department {
  padding-top: 160px;
  opacity: 0;
  transition: opacity 0.7s;
}
.t-main-it-department::-webkit-scrollbar {
  display: none;
}
.t-main-it-department-block {
  position: relative;
}
.t-main-it-department-block .t-background {
  height: 130px;
  width: 100%;
  position: absolute;
  top: calc(50% - 65px);
  left: 0;
  z-index: -1;
}
.t-main-it-department-block .t-work-area {
  padding-right: 0;
}
.t-main-it-department-block__container {
  display: flex;
  padding-left: 86px;
  overflow-x: auto;
  padding-top: 5px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-height: 200px;
}
.t-main-it-department-block__container::-webkit-scrollbar {
  display: none;
}
.t-main-it-department-block__item {
  display: none;
  width: 370px;
  height: 179px;
  padding: 26px 0 24px 32px;
  border: 1px solid rgba(189, 207, 209, 0.5);
  border-radius: 15px 16px 35px 15px;
  background-color: #ffffff;
  position: relative;
  transition: all 0.3s;
}
.t-main-it-department-block__item img {
  position: absolute;
  bottom: -10px;
  right: 0;
}
.t-main-it-department-block__item:active {
  transform: scale(0.99) translateY(2px) !important;
}
@media (min-width: 1024px) {
  .t-main-it-department-block__item:hover {
    transform: scale(1.02) translateY(-2px);
  }
}
@media (max-width: 767px) {
  .t-main-it-department-block__item {
    height: 122px;
  }
}
.t-main-it-department-block__item._visible {
  display: flex;
  flex-direction: column;
  margin-right: 28px;
}
.t-main-it-department-block__item._all {
  min-width: 252px;
  width: 252px;
  cursor: pointer;
}
.t-main-it-department-block__item._all .t-main-it-department-block__img {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% 0;
}
.t-main-it-department-block__item._all .t-main-it-department-block__title {
  height: auto;
  margin: 35px 0 5px;
  max-width: 145px;
  color: #ffffff;
}
.t-main-it-department-block__item._all .t-main-it-department-block__text {
  color: #ffffff;
  font-size: 16px;
}
.t-main-it-department-block__item._all-green {
  background: linear-gradient(251deg, #00db00, #00b500);
}
.t-main-it-department-block__item._all-green .t-main-it-department-block__img {
  background-image: url("/img/ico-one-card-more2.png");
}
.t-main-it-department-block__item._all-green.t-shadow:after {
  background-color: #00ce00;
}
@media (max-width: 920px) {
  .t-main-it-department-block__item._all-green {
    margin-left: 0 !important;
    margin-right: 0;
  }
}
.t-main-it-department-block__item._all-blue {
  background: linear-gradient(256deg, #13e0e0, #08bdbd);
}
.t-main-it-department-block__item._all-blue .t-main-it-department-block__img {
  background-image: url("/img/ico-one-card-more.png");
}
.t-main-it-department-block__item._all-blue.t-shadow:after {
  background-color: #10d8d8;
}
@media (max-width: 920px) {
  .t-main-it-department-block__item._all {
    min-width: 271px;
    width: 271px;
  }
}
.t-main-it-department-block__img {
  position: absolute;
  top: 0;
  right: 0;
}
.t-main-it-department-block__title {
  margin-bottom: auto;
  font-size: 24px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.33;
}
.t-main-it-department-block__title span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: -moz-box;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  box-orient: vertical;
}
@media (max-width: 767px) {
  .t-main-it-department-block__title span {
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }
}
.t-main-it-department-block__text {
  padding-right: 64px;
  font-size: 20px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.6;
  color: #bdcfd1;
}
.t-main-it-department-block__arrow {
  position: absolute;
  bottom: 18px;
  right: 18px;
  width: 39px;
  height: 39px;
  background-color: #ffffff;
  border-radius: 50%;
}
.t-main-it-department-block__arrow span {
  position: absolute;
  top: 15px;
  left: 14px;
  width: 13px;
  height: 3px;
  background-color: #bdcfd1;
  border-radius: 20px;
  transform: rotate(45deg);
}
.t-main-it-department-block__arrow span:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 13px;
  height: 3px;
  background-color: #bdcfd1;
  border-radius: 20px;
  transform: rotate(90deg);
}
.t-main-it-department__content {
  margin-left: 155px;
  max-width: 495px;
}
.t-main-it-department__name {
  font-size: 23px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
}
.t-main-it-department__position {
  max-width: 280px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #a3b6b8;
}
.t-main-it-department__text {
  font-size: 18px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
}
.t-main-people {
  padding-top: 180px;
  opacity: 0;
  transition: opacity 0.7s;
}
.t-main-people__column {
  padding-left: 86px;
}
.t-main-people__container {
  display: flex;
}
.t-main-people__img {
  margin: 78px 0 0 calc((100% - 1440px) / 2);
  padding: 180px 0 30px 70px;
  overflow: hidden;
}
.t-main-people__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.t-main-people__img-1 {
  margin-top: 40px;
  margin-right: 240px;
  width: 435px;
  min-width: 435px;
  height: 308px;
}
.t-main-people__img-2 {
  position: absolute !important;
  right: 38%;
  width: 266px;
  min-width: 266px;
  height: 178px;
}
.t-main-people__img-3 {
  position: absolute !important;
  right: 3%;
  margin-top: -180px;
  width: 429px;
  min-width: 429px;
  height: 285px;
}
.t-main-people__img-4 {
  margin-left: 530px;
  margin-top: -100px;
  margin-right: 108px;
  width: 228px;
  min-width: 228px;
  height: 285px;
}
.t-main-people__img-5 {
  width: 451px;
  min-width: 451px;
  height: 337px;
}
.t-main-people__img-1, .t-main-people__img-2, .t-main-people__img-3, .t-main-people__img-4, .t-main-people__img-5 {
  position: relative;
}
.t-main-people__img-1 .t-background, .t-main-people__img-2 .t-background, .t-main-people__img-3 .t-background, .t-main-people__img-4 .t-background, .t-main-people__img-5 .t-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 28px;
  left: 28px;
  z-index: -1;
}
.t-main-people .t-main-quote__line._bottom {
  bottom: 30px;
}
.t-main-people .t-main-quote__container {
  text-align: end;
  padding-right: 52px;
}
.t-main-salary {
  padding-top: 180px;
  opacity: 0;
  transition: opacity 0.7s;
}
.t-main-salary__block {
  margin-bottom: 100px;
}

._open-block {
  opacity: 1;
}

@media (max-width: 1440px) {
  .t-main-company-swiper {
    margin: 0;
    max-width: 100%;
  }
}
@media (max-width: 1320px) {
  .t-main-people__img {
    margin: 40px 0 0 -120px;
  }
  .t-main-company-swiper {
    padding-left: 166px;
  }
  .t-main-company-swiper .swiper-slide:last-child {
    margin-right: 80px !important;
  }
  .t-main-company-swiper .swiper-wrapper {
    width: fit-content !important;
  }
}
@media (max-width: 1320px) {
  .t-main {
    padding-top: 230px;
  }
  .t-main-top {
    height: 280px;
  }
  .t-main-slider {
    max-width: 580px;
    clip-path: polygon(0 100%, 46.7% 0, 100% 0, 53.3% 100%);
    -webkit-clip-path: polygon(0 100%, 46.7% 0, 100% 0, 53.3% 100%);
  }
  .t-main-slider__pattern {
    right: -72px;
    clip-path: polygon(0 100%, 84.7% 0, 100% 0, 15.3% 100%);
    -webkit-clip-path: polygon(0 100%, 84.7% 0, 100% 0, 15.3% 100%);
  }
  .t-main-slider img {
    clip-path: polygon(0 100%, 46.7% 0, 100% 0, 53.3% 100%);
    -webkit-clip-path: polygon(0 100%, 46.7% 0, 100% 0, 53.3% 100%);
  }
  .t-main-slider .swiper-container {
    height: 280px;
  }
  .t-main-swiper-pagination {
    width: 315px;
  }
  .t-main-it-department-block__text {
    font-size: 16px;
  }
}
@media (max-width: 1250px) {
  .t-main-it__container {
    max-width: 450px;
  }
  .t-main-it__img {
    right: 40px;
  }
  .t-main-it-department__content {
    margin-left: 100px;
    max-width: 410px;
  }
  .t-main-it-department-block__item {
    padding: 15px 0 15px 18px;
  }
  .t-main-bank {
    padding-left: 320px;
  }
  .t-main-bank__btn {
    margin-left: 320px;
  }
  .t-main-quote__line {
    right: calc(100% - 520px);
    width: 520px;
  }
  .t-main-quote__container {
    padding-left: 320px;
  }
  .t-main-people__img {
    margin: 0 auto;
    max-width: 1010px;
    padding: 180px 0 30px 0;
  }
  .t-main-people__img-1 {
    margin-top: 0;
    margin-right: 45px;
  }
  .t-main-people__img-2 {
    position: relative;
    margin-top: 200px;
    right: 3%;
  }
  .t-main-people__img-3 {
    margin-top: -115px;
    margin-left: -200px;
  }
  .t-main-people__img-4 {
    margin-left: 100px;
    margin-top: 28px;
    margin-right: 38px;
  }
  .t-main-people__img-5 {
    margin-top: 100px;
  }
}
@media (max-width: 1080px) {
  .t-main-it-department__content {
    margin-left: 0;
  }
}
@media (max-width: 1000px) {
  .t-main-top {
    height: 225px;
  }
  .t-main-slider {
    max-width: 480px;
    clip-path: polygon(0 100%, 46.7% 0, 100% 0, 53.3% 100%);
    -webkit-clip-path: polygon(0 100%, 46.7% 0, 100% 0, 53.3% 100%);
  }
  .t-main-slider__pattern {
    right: -72px;
    clip-path: polygon(0 100%, 84.7% 0, 100% 0, 15.3% 100%);
    -webkit-clip-path: polygon(0 100%, 84.7% 0, 100% 0, 15.3% 100%);
  }
  .t-main-slider img {
    clip-path: polygon(0 100%, 46.7% 0, 100% 0, 53.3% 100%);
    -webkit-clip-path: polygon(0 100%, 46.7% 0, 100% 0, 53.3% 100%);
  }
  .t-main-slider .swiper-container {
    height: 225px;
  }
  .t-main-swiper-pagination {
    width: 265px;
  }
  .t-main-container {
    margin-bottom: 165px;
  }
  .t-main-container .t-title {
    width: fit-content;
  }
  .t-main-container .t-title img {
    right: -20px;
    transform: scale(0.9);
  }
  .t-main-btn {
    margin-bottom: 70px;
  }
  .t-main-it__container {
    margin-left: 0;
  }
  .t-main-it__img {
    right: 0;
    width: 96px;
    height: 96px;
  }
  .t-main-it__img img {
    transform: scale(0.55);
  }
  .t-main-it__img-1 {
    top: -120px;
    left: -20px;
  }
  .t-main-it__img-2 {
    top: -90px;
    right: 3px;
  }
  .t-main-it-department-block__title {
    font-size: 22px;
  }
  .t-main-it-department-block__text {
    font-size: 13px;
    padding-right: 40px;
  }
  .t-main-it-department-block__item img {
    bottom: -14px;
    right: -10px;
    transform: scale(0.8);
  }
  .t-main-it-department-block__item:not(:last-child) {
    margin-right: 10px;
  }
  .t-main-it-department-block__item._all .t-main-it-department-block__title {
    margin: 14px 0 5px;
    max-width: 120px;
  }
  .t-main-it-department-block__item._all .t-main-it-department-block__text {
    font-size: 13px;
  }
  .t-main-it-department-block__arrow {
    transform: scale(0.8);
    bottom: 16px;
    right: 5px;
  }
  .t-main-it__btn {
    margin-bottom: 160px;
  }
  .t-main-it-department__content {
    margin: 0 auto;
  }
  .t-main-bank {
    padding-left: 200px;
  }
  .t-main-bank__img {
    left: 0;
    top: 50px;
    width: 96px;
    height: 96px;
  }
  .t-main-bank__img span {
    width: 10px;
    bottom: -7px;
  }
  .t-main-bank__img-1 {
    left: -5px;
  }
  .t-main-bank__img-2 {
    left: 20px;
    height: 86px;
  }
  .t-main-bank__img-3 {
    left: 45px;
    height: 124px;
  }
  .t-main-bank__img-4 {
    left: 70px;
    height: 160px;
  }
  .t-main-bank__btn {
    margin-left: 200px;
    margin-bottom: 75px;
  }
  .t-main-quote__line {
    display: none;
  }
  .t-main-quote__line._mobile {
    display: flex;
    width: 100%;
    top: 80px;
    height: 15px;
    background-size: cover;
    right: 0;
  }
  .t-main-quote__container {
    padding-left: 0;
    margin-bottom: 60px;
  }
  .t-main-product__container img {
    max-height: 380px;
  }
  .t-main-product__img {
    margin-top: 42px;
  }
  .t-main-str {
    padding: 16px 0 16px;
    height: 51px;
  }
  .t-main-str__content {
    font-size: 18px;
  }
  .t-main-company-swiper {
    padding-left: 126px;
  }
  .t-main-company-swiper .swiper-slide:last-child {
    margin-right: 40px !important;
  }
  .t-main-company-swiper .swiper-wrapper {
    width: fit-content !important;
  }
}
@media (max-width: 842px) {
  .t-main-block__item:nth-child(3n) {
    margin-right: 20px;
  }
  .t-main-block__item:nth-child(2n) {
    margin-right: 0;
  }
  .t-main-block__item:last-child {
    width: 288px;
    margin-right: 0;
  }
  .t-main-company-swiper {
    max-width: 100%;
  }
  .t-main-company-swiper .swiper-slide:last-child {
    margin-right: 80px !important;
  }
}
@media (max-width: 767px) {
  .t-main-container h1 {
    margin-bottom: 10px;
  }
  .t-main-btn {
    margin: 35px 0;
  }
  .t-main-btn__left {
    margin-right: 12px;
  }
  .t-main-top {
    height: 180px;
  }
  .t-main-slider {
    margin-top: 35px;
    max-width: 340px;
    margin-right: -100px;
    clip-path: polygon(0 100%, 42.7% 0, 100% 0, 71.3% 100%);
    -webkit-clip-path: polygon(0 100%, 42.7% 0, 100% 0, 71.3% 100%);
  }
  .t-main-slider__pattern {
    top: -35px;
    right: -6px;
    z-index: -1;
  }
  .t-main-slider img {
    clip-path: polygon(0 100%, 42.7% 0, 100% 0, 71.3% 100%);
    -webkit-clip-path: polygon(0 100%, 42.7% 0, 100% 0, 71.3% 100%);
  }
  .t-main-slider .swiper-container {
    height: 144px;
  }
  .t-main-bank {
    padding-left: 0;
  }
  .t-main-bank__btn {
    margin-bottom: 0;
  }
  .t-main-it, .t-main-bank {
    padding: 12px 16px 16px;
    border: 1px solid rgba(189, 207, 209, 0.5);
    background-color: #ffffff;
    border-radius: 16px 30px 16px 16px;
  }
  .t-main-it__img, .t-main-bank__img {
    left: 16px;
    right: auto;
    top: -60px;
    z-index: -1;
  }
  .t-main-it__btn, .t-main-bank__btn {
    padding: 16px 78px 16px 16px !important;
    margin-left: 0;
    width: 100%;
  }
  .t-main-it .t-title, .t-main-bank .t-title {
    position: relative;
    width: calc(100% - 50px);
    max-width: 400px;
  }
  .t-main-it .t-title img, .t-main-bank .t-title img {
    top: -22px;
    right: -78px;
  }
  .t-main-quote__title {
    flex-direction: column;
  }
  .t-main-quote__face {
    width: 95px;
    height: 95px;
    margin-right: 0;
    margin-bottom: 19px;
  }
  .t-main-quote__position {
    width: fit-content;
    margin: 0 auto;
  }
  .t-main-quote__text {
    padding: 13px 0;
    text-align: center;
    position: relative;
    white-space: normal;
    line-height: 154%;
  }
  .t-main-quote__text:after {
    content: "";
    position: absolute;
    top: 0;
    left: calc(50% - 77px);
    width: 154px;
    height: 100%;
    border-top: 1px solid rgba(189, 207, 209, 0.4);
    border-bottom: 1px solid rgba(189, 207, 209, 0.4);
    z-index: -1;
  }
  .t-main-block {
    margin-top: 40px;
    padding-top: 46px;
  }
  .t-main-block__title {
    padding: 0 20px;
    margin-bottom: 40px;
    line-height: 1.57;
    font-size: 14px;
    letter-spacing: 5px;
  }
  .t-main-block__item {
    width: 138px;
    margin-bottom: 12px;
  }
  .t-main-block__item:not(:nth-child(3n)), .t-main-block__item:nth-child(3n) {
    margin-right: 12px;
  }
  .t-main-block__item:nth-child(2n) {
    margin-right: 0;
  }
  .t-main-block__item:last-child {
    margin-right: 0;
  }
  .t-main-block__item._all {
    height: 46px;
    background-color: rgba(189, 207, 209, 0.26);
    border-color: rgba(189, 207, 209, 0.26);
    display: flex;
    justify-content: center;
    border-radius: 10px;
  }
  .t-main-block__item._all img {
    display: none;
  }
  .t-main-block__item img {
    transform: scale(0.65);
    margin: -33px 0 0 -34px;
  }
  .t-main-block__subtitle {
    font-size: 14px;
    line-height: 1.36;
  }
  .t-main-block__container {
    max-width: 300px;
    margin-bottom: 70px;
  }
  .t-main-company {
    padding: 20px 0 0;
  }
  .t-main-company__text {
    margin: 16px 0 30px 16px;
  }
  .t-main-company .t-main-cupis {
    margin-bottom: 70px;
    max-width: 100%;
  }
  .t-main-company-swiper {
    padding-left: 32px;
  }
  .t-main-company-swiper .swiper-slide:last-child {
    margin-right: 48px !important;
  }
  .t-main-company-swiper .swiper-wrapper {
    width: fit-content !important;
  }
  .t-main-company-slider {
    margin-bottom: 95px !important;
  }
  .t-main-company-slider .t-swiper-nav {
    display: none;
  }
  .t-main-company-slider__item {
    padding: 10px 18px 20px;
    width: auto;
    height: 100%;
  }
  .t-main-company-slider__container {
    margin-bottom: 12px;
  }
  .t-main-company-slider__container span {
    font-size: 16px;
  }
  .t-main-company-slider__number {
    width: 28px;
    height: 28px;
  }
  .t-main-company-slider__text {
    font-size: 14px;
  }
  .t-main-company-slider__line:after {
    height: 5px;
  }
  .t-main-company__quote {
    padding-top: 64px;
  }
  .t-main-company-swiper2 {
    padding: 30px 0 0 16px;
    margin-bottom: 95px !important;
  }
  .t-main-company-swiper2 .t-swiper-nav {
    display: none;
  }
  .t-main-company-swiper2 .swiper-slide {
    padding-right: 16px;
  }
  .t-main-company-swiper2__name {
    font-size: 16px;
  }
  .t-main-company-swiper2__position {
    font-size: 12px;
  }
  .t-main-company-swiper2__text {
    font-size: 14px;
  }
  .t-main-company-swiper2__pagination {
    display: flex;
  }
  .t-main-company-swiper2__img {
    left: 16px;
    width: 20px;
    height: 14px;
  }
  .t-main-company-swiper2__img2 {
    left: 30px;
    width: 20px;
    height: 14px;
  }
  .t-main-product {
    padding-top: 80px;
  }
  .t-main-product__block {
    padding: 16px;
  }
  .t-main-product__subtitle {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .t-main-product__text {
    font-size: 14px;
  }
  .t-main-product__img {
    padding-bottom: 87vw;
    margin-bottom: 0;
  }
  .t-main-product__img img:last-child {
    position: absolute;
    bottom: 0;
    left: 0;
    max-height: 500px;
    height: auto;
    width: 100%;
  }
  .t-main-product__img .t-background {
    top: 50px;
    height: 370px;
  }
  .t-main-product__container.t-work-area-fullRight {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 342px));
  }
  .t-main-product__container.t-work-area-fullRight img {
    max-width: 100%;
  }
  .t-main-it-department {
    padding-top: 80px;
  }
  .t-main-it-department-block .t-background {
    height: 263px;
    top: calc(50% - 131px);
  }
  .t-main-it-department-block__container {
    flex-wrap: wrap;
    padding-left: 0;
  }
  .t-main-it-department-block__item {
    margin: 0 0 10px 0;
    width: 270px;
  }
  .t-main-it-department-block__item:nth-child(even) {
    margin-left: 16px;
  }
  .t-main-it-department-block__title {
    font-size: 18px;
    font-weight: 600;
  }
  .t-main-it-department-block__position {
    font-size: 13px;
  }
  .t-main-it-department__content {
    padding: 60px 16px 0;
  }
  .t-main-it-department__text {
    font-size: 14px;
  }
  .t-main-people {
    padding-top: 80px;
  }
  .t-main-people__column {
    padding-left: 0;
  }
  .t-main-people__img {
    padding: 80px 0 30px 0;
  }
  .t-main-people__img-1 {
    width: 180px;
    min-width: 180px;
    height: 185px;
  }
  .t-main-people__img-1 .t-background {
    top: 17px;
  }
  .t-main-people__img-2 {
    margin-top: 103px;
    width: 200px;
    min-width: 200px;
    height: 142px;
    right: -78px;
  }
  .t-main-people__img-2 .t-background {
    top: 22px;
  }
  .t-main-people__img-3 {
    right: -75px;
    margin-top: -50px;
    margin-left: 0;
    width: 180px;
    min-width: 180px;
    height: 125px;
  }
  .t-main-people__img-3 .t-background {
    top: 17px;
  }
  .t-main-people__img-4 {
    margin-top: 32px;
    margin-left: -80px;
    width: 197px;
    min-width: 197px;
    height: 200px;
  }
  .t-main-people__img-4 .t-background {
    top: 18px;
  }
  .t-main-people__img-5 {
    position: absolute;
    right: -35px;
    margin-top: 92px;
    width: 200px;
    min-width: 200px;
    height: 164px;
  }
  .t-main-people__img-5 .t-background {
    top: 15px;
  }
  .t-main-salary {
    padding-top: 80px;
  }
  .t-main-salary__block {
    margin-bottom: 60px;
  }
}
footer {
  padding: 140px 0 0;
  order: 10;
  overflow: hidden;
}
footer .t-work-area {
  position: relative;
}
footer > .t-background {
  display: none;
}

.t-footer-vacancy {
  margin-bottom: 70px;
  max-width: 725px;
  display: flex;
  align-items: center;
}
.t-footer-vacancy .t-background {
  height: 100px;
  border-radius: 25px;
  top: calc(50% - 50px);
}
.t-footer-vacancy__block {
  margin-left: 85px;
  display: block;
}
.t-footer-vacancy__text {
  margin-left: 10px;
  max-width: 320px;
  font-size: 14px;
  line-height: 1.5;
}
.t-footer__container {
  padding: 0 130px 0 85px;
  position: relative;
}
.t-footer__text {
  max-width: 825px;
  margin-bottom: 99px;
  font-size: 18px;
  line-height: 1.89;
}
.t-footer__info {
  display: flex;
  flex-direction: column;
}
.t-footer__info a {
  transition: transform 0.3s, opacity 0.3s;
}
.t-footer__info a:active {
  transform: scale(0.95);
}
@media (min-width: 1024px) {
  .t-footer__info a:hover {
    opacity: 1;
  }
}
.t-footer__address {
  font-weight: bold;
}
.t-footer__contact {
  opacity: 0.6;
}
.t-footer__bottom {
  padding-bottom: 46px;
  margin-top: 53px;
  display: flex;
  justify-content: space-between;
  color: #bdcfd1;
}
.t-footer__img {
  position: absolute;
  bottom: 0;
  right: -40px;
}
.t-footer__img img {
  position: absolute;
  bottom: 0;
  right: -40px;
}
.t-footer__modal {
  cursor: pointer;
  margin-right: 50px;
  transition: color 0.3s;
}
@media (min-width: 1024px) {
  .t-footer__modal:hover {
    color: #000000;
  }
}
.t-footer-back {
  display: none;
  margin-bottom: 83px;
  position: relative;
  height: 80px;
  align-items: center;
}
.t-footer-back .t-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: 100%;
  z-index: -10;
}
.t-footer-back__link {
  max-width: 168px;
  width: 168px;
  padding: 4px 18px 5px 5px;
  background-color: #ffffff;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  transition: transform 0.3s;
}
.t-footer-back__link:active {
  transform: scale(0.97) !important;
}
@media (min-width: 1024px) {
  .t-footer-back__link:hover {
    transform: scale(1.04);
  }
}
.t-footer-back._mobile {
  display: none !important;
}

footer._vacancies {
  padding: 0;
}
footer._vacancies .t-main-it-department-block, footer._vacancies .t-footer__text, footer._vacancies .t-footer__img, footer._vacancies .t-footer-back__link ._back {
  display: none;
}
footer._vacancies .t-footer__container {
  padding: 0 258px 0 0;
}
footer._vacancies .t-footer-back {
  display: flex;
}
footer._it-vacancies, footer._banking-vacancies {
  display: flex;
  align-items: flex-end;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
}
footer._it-vacancies .t-work-area, footer._banking-vacancies .t-work-area {
  padding: 0 80px 0 85px;
}
footer._it-vacancies .t-footer__img, footer._it-vacancies .t-footer-back__link ._main, footer._banking-vacancies .t-footer__img, footer._banking-vacancies .t-footer-back__link ._main {
  display: none;
}
footer._it-vacancies .t-footer-back, footer._banking-vacancies .t-footer-back {
  display: flex;
}
footer._it-vacancies .t-footer-back .t-background, footer._banking-vacancies .t-footer-back .t-background {
  display: none;
}
footer._it-vacancies .t-footer-back__link, footer._banking-vacancies .t-footer-back__link {
  width: 125px;
  margin-right: 50px;
}
footer._it-vacancies .t-footer__bottom, footer._banking-vacancies .t-footer__bottom {
  justify-content: flex-start;
}

@media (max-width: 1400px) {
  footer._it-vacancies .t-work-area, footer._banking-vacancies .t-work-area {
    padding: 0 80px 0 60px;
  }
  .t-footer__container {
    padding: 0 130px 0 60px;
  }
  .t-footer-vacancy__block {
    margin-left: 60px;
  }
}
@media (max-width: 1280px) {
  footer._it-vacancies .t-work-area, footer._banking-vacancies .t-work-area {
    padding: 0 80px 0 20px;
  }
  .t-footer-vacancy {
    max-width: 560px;
  }
}
@media (max-width: 1000px) {
  footer._it-vacancies .t-footer-back__link, footer._banking-vacancies .t-footer-back__link {
    margin-right: 0;
  }
  footer._it-vacancies .t-footer__container, footer._banking-vacancies .t-footer__container {
    padding: 0 0 0 40px;
  }
  footer._it-vacancies .t-work-area, footer._banking-vacancies .t-work-area {
    padding: 0 40px 0 20px;
  }
  .t-footer__container {
    padding: 0 170px 0 40px;
  }
  .t-footer-vacancy__block {
    margin-left: 40px;
    margin-right: 0 !important;
  }
  .t-footer__text {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  footer {
    padding: 65px 0 0;
  }
  footer > .t-work-area {
    display: flex;
    flex-direction: column;
  }
  footer._vacancies .t-footer__container {
    padding: 24px 0 0 32px;
    background-color: #ffffff;
    max-width: calc(100% - 5px);
  }
  footer._vacancies .t-footer__bottom span:last-child {
    width: 100%;
    margin-left: 0;
    border-top: 1px solid rgba(189, 207, 209, 0.29);
  }
  footer._it-vacancies, footer._banking-vacancies {
    padding-top: 180px;
    flex-direction: column;
    position: relative;
  }
  footer._it-vacancies .t-work-area, footer._banking-vacancies .t-work-area {
    padding: 0 16px;
  }
  footer._it-vacancies .t-footer__container, footer._banking-vacancies .t-footer__container {
    padding: 0 16px;
  }
  footer._it-vacancies .t-footer__container._mobile, footer._banking-vacancies .t-footer__container._mobile {
    padding: 24px 32px 0;
  }
  footer._it-vacancies .t-footer__img, footer._banking-vacancies .t-footer__img {
    display: block;
  }
  footer._it-vacancies .t-footer-back, footer._banking-vacancies .t-footer-back {
    width: 100%;
    height: 54px;
    margin-bottom: 38px;
  }
  footer._it-vacancies .t-footer-back__link, footer._banking-vacancies .t-footer-back__link {
    width: 100%;
    margin: 0 auto;
    border: 1px solid rgba(189, 207, 209, 0.4);
    background-color: #ffffff;
  }
  footer._it-vacancies .t-footer-back._mobile, footer._banking-vacancies .t-footer-back._mobile {
    display: block !important;
  }
  footer._it-vacancies .t-footer-back._mobile-hidden, footer._banking-vacancies .t-footer-back._mobile-hidden {
    display: none !important;
  }
  footer._it-vacancies > .t-background, footer._banking-vacancies > .t-background {
    display: block;
    width: 100%;
    height: 60px;
    position: absolute;
    top: 70px;
    z-index: 1;
  }
  footer .t-main-it-department-block__item._visible {
    margin: 0;
  }
  .t-footer-vacancy {
    margin-bottom: 55px;
    flex-direction: column;
  }
  .t-footer-vacancy .t-background {
    height: 150px;
  }
  .t-footer-vacancy__text {
    max-width: 236px;
    font-size: 13px;
    margin: 20px 0;
    text-align: center;
  }
  .t-footer__text {
    order: -1;
    margin-bottom: 65px;
  }
  .t-footer__container {
    padding: 0 16px;
  }
  .t-footer__container._mobile {
    padding: 24px 32px 0;
    width: calc(100% + 32px);
    margin-left: -16px;
    background-color: rgba(189, 207, 209, 0.2);
  }
  .t-footer__img {
    right: 0;
    top: 0;
  }
  .t-footer__img img:first-child {
    display: none;
  }
  .t-footer__img img:last-child {
    width: 71px;
    height: 145px;
    bottom: 186px;
    right: 0;
    top: 0;
    filter: brightness(0) invert(1);
  }
  .t-footer__address {
    margin-bottom: 10px;
    order: -2;
    max-width: 205px;
  }
  .t-footer__contact:nth-child(3) {
    order: -1;
    margin-bottom: 25px;
    max-width: 160px;
    color: #78979a;
  }
  .t-footer__contact:not(:nth-child(3)) {
    padding: 12px 0;
    border-top: 1px solid rgba(189, 207, 209, 0.29);
    font-size: 14px;
    opacity: 1;
  }
  .t-footer__bottom {
    margin-top: 0;
    padding-bottom: 0;
    flex-direction: column;
  }
  .t-footer__bottom span:last-child {
    padding: 8px;
    background-color: #ffffff;
    width: calc(100% + 64px);
    margin-left: -32px;
    display: flex;
    justify-content: center;
    font-size: 12px;
  }
  .t-footer__modal {
    width: 100%;
    margin: 0;
    color: #000000;
    padding: 14px 0;
    border-top: 1px solid rgba(189, 207, 209, 0.29);
  }
  .t-footer-back {
    margin-bottom: 10px;
  }
  .t-footer-back .t-background, .t-footer-back .t-arrow {
    display: none;
  }
  .t-footer-back__link {
    border-radius: 10px;
    background-color: rgba(189, 207, 209, 0.26);
    padding: 14px 68px;
    max-width: 320px;
    width: calc(100% - 36px);
    justify-content: center;
  }
  .t-footer-back .t-btn_arrow {
    transform: rotate(180deg);
    filter: brightness(1) invert(1);
    margin-left: 0;
    margin-right: 7px;
  }
}
.t-modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 25;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s;
}
.t-modal._open {
  opacity: 1;
  pointer-events: auto;
}
.t-modal.t-background {
  background-color: rgba(255, 255, 255, 0.9);
}
.t-modal__form {
  width: 100%;
  max-width: 760px;
  opacity: 1;
  transition: opacity 0.4s;
}
.t-modal__form .t-btn {
  margin: 0 auto;
  padding: 19px 46px;
  font-size: 18px;
  color: #ffffff;
  outline: none;
  border: none;
  z-index: 1;
}
.t-modal__form .t-btn._disabled {
  pointer-events: none;
}
.t-modal__form .t-btn._disabled:before {
  background: linear-gradient(253deg, #bdbdbd, #d4d4d4);
}
.t-modal__form._hidden-modal {
  opacity: 0;
  pointer-events: none;
}
.t-modal__window {
  padding: 26px 33px;
  border: 1px solid rgba(189, 207, 209, 0.5);
  border-radius: 16px 35px 16px 16px;
  background-color: #ffffff;
  position: relative;
}
.t-modal__close {
  top: 17px;
  right: 17px;
  width: 31px;
  height: 31px;
  background-color: #e1e1e1;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  transition: transform 0.3s;
}
.t-modal__close span {
  position: absolute;
  top: 14px;
  left: 8px;
  width: 15px;
  height: 3px;
  background-color: #ffffff;
  border-radius: 20px;
  transform: rotate(45deg);
}
.t-modal__close span:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 3px;
  background-color: #ffffff;
  border-radius: 20px;
  transform: rotate(90deg);
}
@media (min-width: 1024px) {
  .t-modal__close:hover {
    transform: scale(1.1);
  }
}
.t-modal__title {
  font-size: 28px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
}
.t-modal__text {
  margin: 10px 0;
  border: none;
  width: 100%;
  height: 230px;
  line-height: 1.44;
  font-size: 16px;
  outline: none;
  resize: none;
}
.t-modal__text:focus::placeholder {
  opacity: 0;
}
.t-modal__text::placeholder {
  font-family: "montserrat", Arial, sans-serif;
  transition: opacity 0.3s;
  color: #bdcfd1;
}
.t-modal__email {
  margin: 80px auto 40px;
  max-width: 440px;
  border-bottom: 1px solid #000000;
  display: flex;
}
.t-modal__email input {
  padding: 12px 0;
  margin-right: 20px;
  font-size: 18px;
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
}
.t-modal__email input:focus::placeholder {
  opacity: 0;
}
.t-modal__email input::placeholder {
  font-family: "montserrat", Arial, sans-serif;
  transition: opacity 0.3s;
  color: #000000;
}
.t-modal__email._border-error {
  border-bottom: 1px solid #ff0000;
}
.t-modal__checkbox {
  margin-bottom: 20px;
}
.t-modal__checkbox label {
  padding-left: 36px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.2s;
}
.t-modal__checkbox label span {
  margin-left: 20px;
}
.t-modal__checkbox label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  border: 1px solid #00b500;
  border-radius: 4px;
  transition: border 0.2s;
}
.t-modal__checkbox label svg {
  position: absolute;
  left: 9px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s;
  width: 18px;
  height: 20px;
}
.t-modal__checkbox input {
  display: none;
}
.t-modal__checkbox input:checked + label svg {
  opacity: 1;
}
.t-modal__checkbox._border-error-checkbox label:before {
  border: 1px solid #ff0000;
}
.t-modal__error {
  display: flex;
  color: #ff0000;
  align-items: center;
  min-width: 150px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}
.t-modal__error-mark {
  width: 20px;
  height: 20px;
  background-color: #ff0000;
  color: #ffffff;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
}
.t-modal__error._open {
  opacity: 1;
  pointer-events: auto;
}
.t-modal-success {
  position: absolute;
  opacity: 0;
  transition: opacity 0.4s 0.4s;
  max-width: 400px;
  pointer-events: none;
}
.t-modal-success._open {
  opacity: 1;
  pointer-events: auto;
}
.t-modal-success .t-modal__title {
  font-size: 36px;
}
.t-modal-success__window {
  width: 100%;
  padding: 40px 42px 50px;
  border-radius: 16px 35px 16px 16px;
  background-color: #ffffff;
}
.t-modal-success__window .t-btn {
  margin-top: 60px;
  border-radius: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
}
.t-modal-success__img {
  position: absolute;
  top: 0;
  right: 0;
  width: 180px;
  height: 115px;
  background: url("/img/serpantin-5.png") no-repeat 0 100%;
}
.t-modal-success__text {
  margin-top: 20px;
}
.t-modal-success__subtext {
  margin: 50px 0 43px 42px;
  max-width: 245px;
  font-size: 14px;
  line-height: 1.5;
}
.t-modal-success__link {
  margin-left: 42px;
  max-width: 323px;
  width: 100%;
  background: linear-gradient(107deg, #ff8900, #ff0061);
  padding: 18px 26px 20px;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.t-modal-success__link span {
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
}
.t-modal-success__link img {
  margin-left: 20px;
}
.t-modal-success__hh {
  font-size: 28px !important;
  margin-left: auto;
  line-height: 1;
}

@media (max-width: 1070px) {
  .t-modal {
    align-items: flex-start;
    padding-top: 20px;
  }
  .t-modal__text {
    height: 100px;
  }
  .t-modal__email {
    margin: 30px auto;
  }
  .t-modal-success {
    height: 100vh;
  }
  .t-modal-success__window {
    margin: 20px auto 0;
    width: calc(100% - 32px);
    border: 1px solid rgba(189, 207, 209, 0.5);
    padding: 28px 18px 23px;
  }
  .t-modal-success__window .t-btn {
    margin-top: 20px;
    font-size: 14px;
  }
  .t-modal-success__window .t-modal__title {
    font-size: 22px;
  }
  .t-modal-success__subtext {
    margin: 27px auto 20px;
    color: #78979a;
  }
  .t-modal-success__link {
    margin: 0 auto;
    width: calc(100% - 64px);
    padding: 12px 26px 12px;
  }
  .t-modal-success__img {
    transform: scale(0.8);
    transform-origin: 100% 0;
  }
}
@media (max-width: 767px) {
  .t-modal {
    padding-top: 0;
  }
  .t-modal__window {
    padding: 60px 16px 20px;
    border: none;
    background-color: transparent;
  }
  .t-modal__close {
    background-color: #ffffff;
    box-shadow: 1px 5px 10px 2px rgba(189, 207, 209, 0.5);
  }
  .t-modal__close span, .t-modal__close span:after {
    background-color: #bdcfd1;
  }
  .t-modal__form {
    height: 100vh;
    background-color: #ffffff;
  }
  .t-modal__form .t-btn {
    margin: 0 auto;
    width: calc(100% - 32px);
    max-width: 400px;
    padding: 14px 46px;
    font-size: 14px;
    display: flex;
    justify-content: center;
  }
  .t-modal__text {
    padding: 0 10px;
    margin: 20px 0;
    height: 100px;
    white-space: normal;
  }
  .t-modal__email {
    margin: 30px 16px 54px;
    border-bottom: 1px solid #bdcfd1;
  }
  .t-modal__email input {
    padding: 10px;
    font-size: 14px;
    margin-right: 0;
  }
  .t-modal__email .t-modal__error {
    display: none;
  }
  .t-modal__error {
    align-items: flex-start;
  }
  .t-modal-success {
    background-color: #ffffff;
  }
  .t-modal__checkbox {
    padding: 0 16px 20px;
  }
}
.t-vacancies {
  padding-top: 204px;
  margin-bottom: 132px;
}
.t-vacancies__container {
  margin-bottom: 120px;
  position: relative;
}
.t-vacancies-tags {
  display: flex;
  flex-wrap: wrap;
  max-width: 450px;
  margin-bottom: 57px;
}
.t-vacancies-tags__item {
  margin: 0 10px 10px 0;
  padding: 13px 25px;
  color: #a3b6b8;
  border-radius: 4px;
  font-weight: 400;
  cursor: pointer;
  transform: translateY(0);
  transition: transform 0.3s, color 0.3s, border-radius 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 10;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
.t-vacancies-tags__item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc(100% + 80px);
  background: linear-gradient(75deg, #00d300, #0fd3d3);
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  opacity: 0.1;
  transform: translateX(-80px);
  transition: transform 0.3s ease-in, filter 0.3s, opacity 0.3s;
  z-index: -1;
}
@media (min-width: 1024px) {
  .t-vacancies-tags__item:hover {
    transform: translateY(-2px);
  }
  .t-vacancies-tags__item:hover:before {
    transform: translateX(0);
  }
}
.t-vacancies-tags__item._active {
  color: #ffffff;
}
.t-vacancies-tags__item._active:before {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  opacity: 1 !important;
}
.t-vacancies-block {
  position: relative;
}
.t-vacancies-block .t-background {
  position: absolute;
  top: calc(50% - (100% - 168px) / 2);
  height: calc(100% - 198px);
  min-height: 130px;
  width: 100%;
  z-index: -10;
}
.t-vacancies-block__container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1300px;
}
.t-vacancies-block__container .t-main-it-department-block__item {
  margin-bottom: 28px;
  overflow: hidden;
}
.t-vacancies-block__container .t-main-it-department-block__item._all-vacancies {
  display: block;
  max-width: 289px;
  border-radius: 16px;
}
.t-vacancies-block__container .t-main-it-department-block__item._all-vacancies .t-main-it-department-block__title {
  margin-top: 37px;
  max-width: 200px;
  margin-bottom: 29px;
}
.t-vacancies-block__container .t-main-it-department-block__title {
  max-width: 260px;
  font-size: 21px;
  line-height: 1.48;
}
.t-vacancies-block__hh {
  padding: 35px 38px;
  font-size: 30px;
  font-weight: bold;
  font-family: "manrope", Arial, sans-serif;
  line-height: 1;
  color: #ffffff;
  position: absolute;
  top: -49px;
  right: -40px;
  width: 136px;
  height: 136px;
  background-image: linear-gradient(144deg, #ff8900, #ff0061);
  border-radius: 50%;
  display: flex;
  align-items: flex-end;
}
.t-vacancies__quote .t-main-quote__line {
  right: calc(100% - (100% - 1440px) / 2 - 0px - 580px);
}
.t-vacancies__quote .t-main-quote__container {
  padding-left: 325px;
}
.t-vacancies-it {
  margin-left: 0;
}
.t-vacancies-bank {
  margin-bottom: 43px;
}
.t-vacancies__btn .t-main-it__btn {
  margin-bottom: 30px;
}
.t-vacancies__btn .t-main-bank__btn {
  margin-bottom: 110px;
}

@media (max-width: 1400px) {
  .t-vacancies-block__container .t-main-it-department-block__item {
    width: 338px;
  }
}
@media (max-width: 1220px) {
  .t-vacancies-block__container .t-main-it-department-block__item {
    width: 265px;
  }
  .t-vacancies-block__container .t-main-it-department-block__text {
    font-size: 16px;
  }
}
@media (max-width: 1000px) {
  .t-vacancies-block__container .t-main-it-department-block__item {
    width: 225px;
  }
  .t-vacancies-block__container .t-main-it-department-block__item._all-vacancies .t-main-it-department-block__title {
    margin-top: 38px;
    max-width: 180px;
    margin-bottom: 10px;
  }
  .t-vacancies-block__container .t-main-it-department-block__title {
    font-size: 18px;
  }
  .t-vacancies-block__container .t-main-it-department-block__text {
    font-size: 13px;
    line-height: 33px;
  }
  .t-vacancies-block__hh {
    transform: scale(0.65);
  }
  .t-vacancies__quote .t-main-quote__line {
    right: 0;
  }
  .t-vacancies__quote .t-main-quote__container {
    padding-left: 0;
  }
  .t-vacancies__btn {
    margin-top: 10px;
    max-width: calc(100% - 50px);
  }
  .t-vacancies__btn .t-main-bank__btn {
    margin-bottom: 90px;
  }
}
@media (max-width: 840px) {
  .t-vacancies-block__container .t-main-it-department-block__item {
    width: 200px;
  }
}
@media (max-width: 767px) {
  .t-vacancies {
    padding-top: 107px;
    margin-bottom: 60px;
  }
  .t-vacancies-block__container {
    padding-right: 16px;
  }
  .t-vacancies-block__container .t-main-it-department-block__item {
    width: 100%;
    max-width: 360px;
    margin-right: 16px !important;
    margin-bottom: 10px;
    margin-left: 0 !important;
  }
  .t-vacancies-block__container .t-main-it-department-block__item._all-vacancies {
    max-width: 360px;
  }
  .t-vacancies-block__container > .t-animate-item {
    width: 100%;
  }
  .t-vacancies-bank {
    padding-left: 16px;
    margin-bottom: 10px;
  }
  .t-vacancies__container {
    margin-right: 36px;
    margin-bottom: 44px;
  }
  .t-vacancies-tags {
    max-width: calc(100% - 36px);
  }
  .t-vacancies__quote .t-main-it-department__content {
    padding: 60px 36px 0 16px;
  }
}
.t-it {
  padding: 204px 0 0;
}
.t-it__container {
  padding-left: 85px;
}
.t-it__text {
  max-width: 648px;
  margin: 16px 0 50px;
}
.t-it__text_small {
  max-width: 566px;
  margin: 36px 0 50px;
}
.t-it-product {
  padding: 142px 0 0 80px;
  display: flex;
  overflow: hidden;
}
.t-it-product__btn {
  margin-top: 40px;
  font-family: "manrope", Arial, sans-serif;
  padding: 11px 30px;
  border-radius: 4px;
}
.t-it-product__btn span {
  font-size: 14px;
  font-weight: 300;
}
.t-it-product__btn:before {
  background: linear-gradient(75deg, #00d300, #0fd3d3);
}
.t-it-product__block {
  max-width: 360px;
  border-radius: 20px;
  padding: 22px 32px;
  color: #a3b6b8;
  font-size: 19px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
}
.t-it-product-img {
  margin: 0 0 40px -160px;
  padding-left: 200px;
  position: relative;
}
.t-it-product-img .t-background {
  top: calc(50% - 100px);
}
.t-it-product-img__container {
  display: flex;
  align-items: flex-end;
}
.t-it-product-img__container img:nth-child(-n+2) {
  max-height: 500px;
}
.t-it-product-img__container img:last-child {
  max-height: 620px;
}
.t-it-product__subtext {
  margin-bottom: 70px;
  max-width: 520px;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
  line-height: 2;
}
.t-it-product-logo__container {
  margin-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  max-width: 830px;
}
.t-it-product-logo__item {
  width: fit-content;
  margin: 0 28px 50px;
  display: flex;
  align-items: center;
}
.t-it-product-logo__item img {
  max-height: 85px;
}
.t-it-product-logo__item:first-child {
  margin-left: 0;
}
.t-it-product-logo__item:nth-child(5) {
  margin-left: 45px;
}
.t-it-product-logo__item:nth-child(9) {
  margin-left: 80px;
}
.t-it-product-logo__item:nth-child(13) {
  margin-left: 125px;
}
.t-it-product-logo__item:nth-child(17) {
  margin-left: 200px;
}
.t-it-product-logo__item:nth-child(20) {
  margin-left: 280px;
}
.t-it-product-logo__item:nth-child(-4n+16) {
  margin-right: 0;
}
.t-it__left {
  width: 345px;
  min-width: 345px;
  margin-right: 85px;
  margin-top: 270px;
  z-index: 1;
}
.t-it__left h4 {
  margin-bottom: 30px;
  font-size: 19px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
  opacity: 0.22;
}
.t-it__left .t-main-it-department-block__item {
  width: 100%;
  margin-bottom: 20px;
}
.t-it__left .t-main-it-department-block__item img {
  bottom: 28px;
  right: 28px;
}
.t-it__left .t-main-it-department-block__title {
  padding-right: 20px;
  font-size: 21px;
  max-width: 270px;
  line-height: 1.48;
  height: auto;
}
.t-it__right {
  width: 100%;
}
.t-it-best {
  margin-bottom: 130px;
}
.t-it-best__swiper {
  margin-right: 135px;
}
.t-it-best__swiper._win {
  margin-right: 144px;
}
.t-it-best__swiper .swiper-slide {
  filter: grayscale(0.8);
  -webkit-filter: grayscale(0.8);
  opacity: 0.3;
}
.t-it-best__swiper .swiper-slide.swiper-slide-active, .t-it-best__swiper .swiper-slide.swiper-slide-active .t-main-company-swiper2__text {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  opacity: 1;
}
.t-it-best__swiper .swiper-slide:last-child {
  width: 100% !important;
  margin-right: 0 !important;
}
.t-it-best__swiper .swiper-slide:last-child .t-main-company-swiper2__text {
  max-width: 478px;
}
.t-it-cupis__column {
  margin: 74px 0 38px;
}
.t-it-cupis__column .t-column__item {
  max-width: 667px;
}
.t-it-cupis__swiper {
  margin-right: 135px;
}
.t-it-cupis__swiper._win {
  margin-right: 144px;
}
.t-it-cupis__swiper .swiper-slide .t-main-company-swiper2__text {
  color: #bdcfd1;
}
.t-it-cupis__swiper .swiper-slide {
  color: #bdcfd1;
}
.t-it-cupis__swiper .swiper-slide.swiper-slide-active, .t-it-cupis__swiper .swiper-slide.swiper-slide-active .t-main-company-swiper2__text {
  color: #000000;
}
.t-it-cupis__swiper .swiper-slide:last-child {
  width: 100% !important;
  margin-right: 0 !important;
}
.t-it-cupis__swiper .swiper-slide:last-child .t-main-company-swiper2__text {
  max-width: 478px;
}
.t-it-vacancies {
  padding: 0 0 0 80px;
  display: flex;
}
.t-it-vacancies__tags {
  max-width: 240px;
}
.t-it-vacancies__tags .t-vacancies-tags__item {
  color: #ffffff;
}
.t-it-vacancies__tags .t-vacancies-tags__item:before {
  background: linear-gradient(251deg, #00db00, #00b500);
  opacity: 0.6;
}
.t-it-vacancies__tags._banking {
  max-width: 300px;
}
.t-it-vacancies__tags._banking .t-vacancies-tags__item {
  padding: 10px 20px;
}
.t-it-vacancies__tags._banking .t-vacancies-tags__item:first-child, .t-it-vacancies__tags._banking .t-vacancies-tags__item:last-child {
  padding: 10px 50px;
}
.t-it-vacancies__tags._banking .t-vacancies-tags__item:before {
  background: linear-gradient(256deg, #13e0e0, #08bdbd);
  opacity: 0.6;
}
.t-it-vacancies__text {
  margin-bottom: 78px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
  color: #a3b6b8;
  font-size: 24px;
}
.t-it-vacancies__block {
  margin-left: -160px;
}
.t-it-vacancies__block .t-vacancies-block__container {
  margin-left: 166px;
  max-width: 1000px;
}

.t-banking-product {
  margin: 0 0 70px -160px;
  padding-left: 125px;
  display: flex;
}
.t-banking-product p {
  padding-left: 120px;
  font-size: 19px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
  color: #a3b6b8;
}
.t-banking-product__container {
  width: 520px;
  margin-top: 165px;
  margin-right: 20px;
}
.t-banking-product__img {
  margin-top: 85px;
  display: flex;
}
.t-banking-product__item {
  margin-right: 40px;
  height: 80px;
  display: flex;
  align-items: center;
}
.t-banking-product__item img {
  max-height: 100%;
  height: auto !important;
  width: auto !important;
  position: relative !important;
  bottom: auto !important;
  left: auto !important;
  max-width: 160px;
  object-fit: contain;
}
.t-banking-product .t-background {
  height: 250px;
  top: calc(50% - 165px);
}
.t-banking-product-logo {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 86px;
  max-width: 650px;
}
.t-banking-product-logo__item {
  height: 65px;
  width: 120px;
  margin: 0 90px 60px 0;
  display: flex;
  align-items: center;
}
.t-banking-product-logo__item img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

@media (max-width: 1440px) {
  .t-it__container {
    padding-left: 75px;
  }
}
@media (max-width: 1400px) {
  .t-it__container {
    padding-left: 60px;
  }
  .t-it__left {
    width: 300px;
    min-width: 300px;
    margin-right: 60px;
  }
}
@media (max-width: 1280px) {
  .t-it__container {
    padding-left: 16px;
  }
  .t-it-product-img {
    padding-left: 150px;
  }
  .t-it-product-logo__item {
    margin: 0 28px 50px;
  }
  .t-it-product-logo__item:first-child {
    margin-left: 28px;
  }
  .t-it-product-logo__item:nth-child(5) {
    margin-left: 28px;
  }
  .t-it-product-logo__item:nth-child(9) {
    margin-left: 28px;
  }
  .t-it-product-logo__item:nth-child(13) {
    margin-left: 28px;
  }
  .t-it-product-logo__item:nth-child(17) {
    margin-left: 28px;
  }
  .t-it-product-logo__item:nth-child(20) {
    margin-left: 28px;
  }
  .t-it-product-logo__item:nth-child(-4n+16) {
    margin-right: 28px;
  }
  .t-it-vacancies__block .t-vacancies-block__container {
    margin-left: 75px;
  }
  .t-it__text {
    max-width: 490px;
  }
  .t-it__text_small {
    max-width: 390px;
  }
  .t-banking-product p {
    padding-left: 50px;
  }
  .t-banking-product__container {
    width: 440px;
    margin-top: 90px;
  }
  .t-banking-product .t-it-product-img__container img:last-child {
    max-height: 360px;
  }
  .t-banking-product-logo__item {
    min-width: 94px;
    width: 94px;
  }
}
@media (max-width: 1000px) {
  .t-it-product {
    padding: 80px 0 0 40px;
    flex-direction: column;
  }
  .t-it__left {
    margin: 36px 0 0;
    width: 100%;
    min-width: 100%;
  }
  .t-it__left h4 {
    margin-bottom: 20px;
  }
  .t-it__left .t-main-it-department-block__item {
    max-width: 280px;
    display: inline-flex;
    flex-direction: column;
  }
  .t-it__right {
    margin-top: 80px;
  }
  .t-it-best__swiper, .t-it-cupis__swiper {
    margin-right: 0;
  }
  .t-it-vacancies {
    flex-direction: column;
  }
  .t-it-vacancies__text {
    margin-bottom: 43px;
  }
  .t-it-vacancies__tags {
    max-width: 400px;
    margin-bottom: 0;
  }
  .t-it-vacancies__block {
    margin-left: -80px;
  }
  .t-banking-product {
    margin: 0 0 70px -40px;
    padding-left: 40px;
  }
  .t-banking-product p {
    padding-left: 16px;
  }
}
@media (max-width: 767px) {
  .t-it {
    padding: 90px 0 0;
  }
  .t-it__container .t-btn {
    width: calc(100% + 16px);
    margin-left: -16px;
    display: flex;
    justify-content: center;
  }
  .t-it-product {
    padding: 36px 16px 0;
  }
  .t-it-product__btn {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
  }
  .t-it-product__btn span {
    color: #000000;
  }
  .t-it-product__btn:after {
    display: none;
  }
  .t-it-product__btn:before {
    background: rgba(189, 207, 209, 0.26);
  }
  .t-it-product .t-main-it__text {
    margin-bottom: 10px;
  }
  .t-it-product .t-it-product-img {
    margin: 0 0 40px -16px;
    padding-left: 16px;
    width: calc(100% + 32px);
  }
  .t-it-product__block {
    font-size: 14px;
    max-width: 256px;
  }
  .t-it-product-img {
    padding-bottom: 510px !important;
  }
  .t-it-product-img .t-background {
    top: 50px;
  }
  .t-it-product-img__container img:nth-child(-n+2) {
    max-height: 400px;
  }
  .t-it-product-img__container img:last-child {
    max-height: 520px;
  }
  .t-it-product__subtext {
    font-size: 13px;
    color: #5a878b;
    font-style: italic;
    text-align: center;
    padding: 11px 16px 16px 0;
    position: relative;
  }
  .t-it-product__subtext:after {
    content: "";
    position: absolute;
    top: 0;
    left: calc(50% - 77px);
    width: 154px;
    height: 100%;
    border-top: 1px solid rgba(189, 207, 209, 0.4);
    border-bottom: 1px solid rgba(189, 207, 209, 0.4);
    z-index: -1;
  }
  .t-it-product-logo {
    width: calc(100% + 48px + 60px);
    margin-left: -32px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-x;
    margin-bottom: 40px;
    transform: translateX(-60px) !important;
  }
  .t-it-product-logo::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  .t-it-product-logo__item.t-animate-item {
    transition-delay: 0s !important;
  }
  .t-it-product-logo__container {
    margin-bottom: 0;
    min-width: 2100px;
    display: flex;
    flex-wrap: wrap;
  }
  .t-it-best > .t-work-area-marginLeft > .t-it__container, .t-it-cupis > .t-work-area-marginLeft > .t-it__container, .t-it-salary > .t-work-area-marginLeft > .t-it__container {
    padding-left: 0;
  }
  .t-it-best {
    margin-bottom: 30px;
  }
  .t-it-cupis__column {
    margin: 30px 0 43px;
  }
  .t-it-salary .t-it__text_small {
    margin: 20px 0 50px;
  }
  .t-it__left {
    margin-top: 0;
  }
  .t-it__left .t-main-it-department-block__item._visible {
    margin: 0 0 10px 0;
  }
  .t-it__left .t-main-it-department-block__item._visible:nth-child(odd) {
    margin-left: 16px;
  }
  .t-it-vacancies {
    padding: 40px 0 0 16px;
  }
  .t-it-vacancies__text {
    font-size: 16px;
  }
  .t-it-vacancies .t-it__right {
    margin-top: 40px;
  }
  .t-banking-product {
    margin: 0 0 70px 0;
    padding-left: 16px;
    padding-bottom: 400px;
    overflow: visible;
  }
  .t-banking-product p {
    padding-left: 0;
    font-size: 14px;
  }
  .t-banking-product-logo {
    width: calc(100% + 48px + 32px);
    padding-left: 16px;
    margin-left: -32px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-x;
    margin-bottom: 40px;
    transform: translateX(-32px) !important;
  }
  .t-banking-product-logo::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  .t-banking-product-logo .t-it-product-logo__container {
    min-width: auto;
    flex-wrap: nowrap;
  }
  .t-banking-product-logo__item {
    margin: 0 50px 60px 0;
  }
  .t-banking-product-logo__item:last-child {
    padding-right: 32px;
    min-width: 126px;
  }
  .t-banking-product-logo__item.t-animate-item {
    transition-delay: 0s !important;
  }
  .t-banking-product .t-background {
    top: 0;
    height: 150px;
  }
  .t-banking-product__container {
    margin-top: 20px;
    width: calc(100% - 16px);
  }
  .t-banking-product__img {
    width: calc(100% + 64px);
    padding-left: 32px;
    margin-left: -32px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-x;
    padding-bottom: 20px;
  }
  .t-banking-product__img::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  .t-banking-product__item:last-child {
    padding-right: 16px;
  }
}
.t-404 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.t-404.t-work-area {
  padding: 170px 80px 100px 252px;
}
.t-404__block {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  background-color: #5a878b;
  color: #ffffff;
  font-size: 42px;
  margin-bottom: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
}
.t-404__title {
  font-size: 36px;
  max-width: 450px;
  font-family: "manrope", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.33;
  margin-bottom: 50px;
}
.t-404__text {
  font-size: 14px;
  max-width: 348px;
  margin-bottom: 35px;
}
.t-404__container {
  display: flex;
  margin-bottom: 100px;
}
.t-404__btn {
  padding: 11px 30px;
}
.t-404__btn span {
  font-size: 14px;
  font-weight: 300;
}
.t-404__btn:before {
  background: linear-gradient(75deg, #00d300, #0fd3d3);
}
.t-404__btn._left {
  margin-right: 20px;
}
.t-404__btn .t-btn_img {
  background-image: url("/img/ico-it-btn.png");
  opacity: 0.5;
}
.t-404__btn._img {
  padding: 11px 60px 11px 30px;
}
.t-404__bottom {
  max-width: 765px;
  width: calc(100% - 310px);
  min-width: 480px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.t-404__bottom span {
  color: #bdcfd1;
}
.t-404-one .t-one {
  transform: translate3d(calc(100% - 345px - (100vw - 1440px) / 2), 0, 0) scale3d(1, 1, 1);
}
.t-404-one .t-one._background {
  margin: 204px 200px 0 0;
}
.t-404-one .t-one._background .t-one__top {
  border: none;
  clip-path: polygon(0 100%, 4% 0, 100% 0, 100% 100%);
}
.t-404-one .t-one._background .t-one__bottom {
  background: radial-gradient(circle, rgba(0, 0, 0, 0.06) 0.0753em, transparent 0.09789em) 0 0, radial-gradient(circle, rgba(0, 0, 0, 0.06) 0.0753em, transparent 0.09789em) 0.251em 0.251em;
  background-size: 0.502em 0.502em;
  background-color: transparent;
  background-repeat: repeat;
}

@media (max-width: 1440px) {
  .t-404-one .t-one {
    transform: translate3d(calc(100% - 345px), 0, 0) scale3d(1, 1, 1);
  }
}
@media (max-width: 1280px) {
  .t-404.t-work-area {
    padding: 120px 80px 100px 80px;
  }
}
@media (max-width: 1000px) {
  .t-404-one .t-one {
    transform: translate3d(calc(100% - 77px), 0, 0) scale3d(1, 1, 1);
  }
  .t-404-one .t-one._background {
    display: none;
  }
  .t-404-one.t-one-container {
    margin-top: -46px;
    height: calc(100% + 46px);
  }
}
@media (max-width: 767px) {
  .t-404.t-work-area {
    padding: 22px 66px 28px 16px;
  }
  .t-404__block {
    width: 54px;
    height: 54px;
    font-size: 20px;
    margin-bottom: 18px;
  }
  .t-404__title {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .t-404__text {
    font-size: 12px;
    margin-bottom: 25px;
  }
  .t-404__container {
    margin-bottom: 50px;
    flex-direction: column;
  }
  .t-404__btn {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .t-404__btn._left {
    margin-right: 0;
  }
  .t-404__btn._left:before {
    background: linear-gradient(259deg, #00db00, #00b500);
  }
  .t-404__btn._img {
    padding: 11px 30px;
  }
  .t-404__btn._img:before {
    background: rgba(189, 207, 209, 0.26);
  }
  .t-404__btn._img .t-btn_img, .t-404__btn._img .t-btn_number {
    display: none;
  }
  .t-404__btn._img span {
    color: #000000;
  }
  .t-404__bottom {
    min-width: 100%;
  }
  .t-404__bottom .t-logo {
    display: none;
  }
  .t-404__bottom span {
    font-size: 12px;
  }
}