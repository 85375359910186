.t-it
    padding: 204px 0 0
    &__container
        padding-left: 85px
    &__text
        max-width: 648px
        margin: 16px 0 50px
        &_small
            max-width: 566px
            margin: 36px 0 50px

    &-product
        padding: 142px 0 0 80px
        display: flex
        overflow: hidden
        &__btn
            margin-top: 40px
            font-family: 'manrope', Arial, sans-serif
            padding: 11px 30px
            border-radius: 4px
            span
                font-size: 14px
                font-weight: 300
            &:before
                background: linear-gradient(75deg, #00d300, #0fd3d3)
        &__block
            max-width: 360px
            border-radius: 20px
            padding: 22px 32px
            color: $gray-base
            font-size: 19px
            font-family: 'manrope', Arial, sans-serif
            font-weight: 300
        &-img
            margin: 0 0 40px -160px
            padding-left: 200px
            position: relative
            .t-background
                top: calc(50% - 100px)
            &__container
                display: flex
                align-items: flex-end
                img:nth-child(-n + 2)
                    max-height: 500px
                img:last-child
                    max-height: 620px
        &__subtext
            margin-bottom: 70px
            max-width: 520px
            font-size: 14px
            font-weight: 300
            font-style: italic
            line-height: 2

        &-logo
            &__container
                margin-bottom: 100px
                display: flex
                flex-wrap: wrap
                max-width: 830px
            &__item
                width: fit-content
                margin: 0 28px 50px
                display: flex
                align-items: center
                img
                    max-height: 85px
                &:first-child
                    margin-left: 0
                &:nth-child(5)
                    margin-left: 45px
                &:nth-child(9)
                    margin-left: 80px
                &:nth-child(13)
                    margin-left: 125px
                &:nth-child(17)
                    margin-left: 200px
                &:nth-child(20)
                    margin-left: 280px
                &:nth-child(-4n + 16)
                    margin-right: 0

    &__left
        width: 345px
        min-width: 345px
        margin-right: 85px
        margin-top: 270px
        z-index: 1
        h4
            margin-bottom: 30px
            font-size: 19px
            font-family: 'manrope', Arial, sans-serif
            font-weight: 300
            opacity: .22
        .t-main-it-department-block__item
            width: 100%
            margin-bottom: 20px
            img
                bottom: 28px
                right: 28px
        .t-main-it-department-block__title
            padding-right: 20px
            font-size: 21px
            max-width: 270px
            line-height: 1.48
            height: auto
    &__right
        width: 100%

    &-best
        margin-bottom: 130px
        &__swiper
            margin-right: 135px
            &._win
                margin-right: 144px
            .swiper-slide
                filter: grayscale(.8)
                -webkit-filter: grayscale(.8)
                opacity: .3
                &.swiper-slide-active, &.swiper-slide-active .t-main-company-swiper2__text
                    filter: grayscale(0)
                    -webkit-filter: grayscale(0)
                    opacity: 1
                &:last-child
                    width: 100% !important
                    margin-right: 0 !important
                    .t-main-company-swiper2__text
                        max-width: 478px

    &-cupis
        &__column
            margin: 74px 0 38px
            .t-column__item
                max-width: 667px
        &__swiper
            margin-right: 135px
            &._win
                margin-right: 144px
            .swiper-slide .t-main-company-swiper2__text
                color: #bdcfd1
            .swiper-slide
                color: #bdcfd1
                &.swiper-slide-active, &.swiper-slide-active .t-main-company-swiper2__text
                    color: $text-base
                &:last-child
                    width: 100% !important
                    margin-right: 0 !important
                    .t-main-company-swiper2__text
                        max-width: 478px

    &-vacancies
        padding: 0 0 0 80px
        display: flex
        &__tags
            max-width: 240px
            .t-vacancies-tags__item
                color: #ffffff
                &:before
                    background: $gradient-green
                    opacity: .6
            &._banking
                max-width: 300px
                .t-vacancies-tags__item
                    padding: 10px 20px
                    &:first-child, &:last-child
                        padding: 10px 50px
                    &:before
                        background: $gradient-blue
                        opacity: .6
        &__text
            margin-bottom: 78px
            font-family: 'manrope', Arial, sans-serif
            font-weight: 300
            color: $gray-base
            font-size: 24px
        &__block
            margin-left: -160px
            .t-vacancies-block__container
                margin-left: 166px
                max-width: 1000px
            //.t-vacancies-block__container .t-main-it-department-block__item:nth-child(6n + 4)
                margin-left: 0
            //.t-vacancies-block__container .t-main-it-department-block__item:nth-child(4n + 3)
                margin-left: 86px
            //.t-vacancies-block__container .t-main-it-department-block__item:nth-child(3n)
                margin-right: 30px
            //.t-vacancies-block__container .t-main-it-department-block__item:nth-child(2n)
                margin-right: 0


.t-banking
    &-product
        margin: 0 0 70px -160px
        padding-left: 125px
        display: flex
        p
            padding-left: 120px
            font-size: 19px
            font-family: 'manrope', Arial, sans-serif
            font-weight: 300
            color: $gray-base
        &__container
            width: 520px
            margin-top: 165px
            margin-right: 20px
        &__img
            margin-top: 85px
            display: flex
        &__item
            margin-right: 40px
            height: 80px
            display: flex
            align-items: center
            img
                max-height: 100%
                height: auto !important
                width: auto !important
                position: relative !important
                bottom: auto !important
                left: auto !important
                max-width: 160px
                object-fit: contain
        .t-background
            height: 250px
            top: calc(50% - 165px)

        &-logo
            display: flex
            flex-wrap: wrap
            margin: 0 0 0 86px
            max-width: 650px
            &__item
                height: 65px
                width: 120px
                margin: 0 90px 60px 0
                display: flex
                align-items: center
                img
                    max-height: 100%
                    max-width: 100%
                    object-fit: contain




@media (max-width: 1440px)
    .t-it
        &__container
            padding-left: 75px


@media (max-width: 1400px)
    .t-it
        &__container
            padding-left: 60px
        &__left
            width: 300px
            min-width: 300px
            margin-right: 60px

@media (max-width: 1280px)
    .t-it
        &__container
            padding-left: 16px
        &-product
            &-img
                padding-left: 150px
            &-logo
                &__item
                    margin: 0 28px 50px
                    &:first-child
                        margin-left: 28px
                    &:nth-child(5)
                        margin-left: 28px
                    &:nth-child(9)
                        margin-left: 28px
                    &:nth-child(13)
                        margin-left: 28px
                    &:nth-child(17)
                        margin-left: 28px
                    &:nth-child(20)
                        margin-left: 28px
                    &:nth-child(-4n + 16)
                        margin-right: 28px
        &-vacancies
            &__block
                .t-vacancies-block__container
                    margin-left: 75px
        &__text
            max-width: 490px
            &_small
                max-width: 390px

    .t-banking
        &-product
            p
                padding-left: 50px
            &__container
                width: 440px
                margin-top: 90px
            .t-it-product-img__container img:last-child
                max-height: 360px
            &-logo
                &__item
                    min-width: 94px
                    width: 94px


@media (max-width: 1000px)
    .t-it
        &-product
            padding: 80px 0 0 40px
            flex-direction: column

        &__left
            margin: 36px 0 0
            width: 100%
            min-width: 100%
            h4
                margin-bottom: 20px
            .t-main-it-department-block__item
                max-width: 280px
                display: inline-flex
                flex-direction: column
        &__right
            margin-top: 80px
        &-best__swiper, &-cupis__swiper
            margin-right: 0
        &-vacancies
            flex-direction: column
            &__text
                margin-bottom: 43px
            &__tags
                max-width: 400px
                margin-bottom: 0
            &__block
                margin-left: -80px

    .t-banking
        &-product
            margin: 0 0 70px -40px
            padding-left: 40px
            p
                padding-left: 16px


@media (max-width: 767px)
    .t-it
        padding: 90px 0 0
        &__container
            .t-btn
                width: calc(100% + 16px)
                //max-width: 320px
                margin-left: -16px
                display: flex
                justify-content: center
        &-product
            padding: 36px 16px 0
            &__btn
                width: 100%
                margin-top: 20px
                display: flex
                justify-content: center
                border-radius: 10px
                span
                    color: $text-base
                &:after
                    display: none
                &:before
                    background: rgba(#bdcfd1, .26)
            .t-main-it__text
                margin-bottom: 10px
            .t-it-product-img
                margin: 0 0 40px -16px
                padding-left: 16px
                width: calc(100% + 32px)
            &__block
                font-size: 14px
                max-width: 256px
            &-img
                padding-bottom: 510px !important
                .t-background
                    top: 50px
                &__container
                    img:nth-child(-n + 2)
                        max-height: 400px
                    img:last-child
                        max-height: 520px
            &__subtext
                font-size: 13px
                color: $text-add
                font-style: italic
                text-align: center
                padding: 11px 16px 16px 0
                position: relative
                &:after
                    content: ''
                    position: absolute
                    top: 0
                    left: calc(50% - 77px)
                    width: 154px
                    height: 100%
                    border-top: 1px solid rgba(#bdcfd1, .4)
                    border-bottom: 1px solid rgba(#bdcfd1, .4)
                    z-index: -1

            &-logo
                width: calc(100% + 48px + 60px)
                margin-left: -32px
                overflow-x: auto
                -webkit-overflow-scrolling: touch
                touch-action: pan-x
                margin-bottom: 40px
                transform: translateX(-60px) !important
                &::-webkit-scrollbar
                    display: none
                    width: 0

                &__item.t-animate-item
                    transition-delay: 0s !important
                &__container
                    margin-bottom: 0
                    min-width: 2100px
                    display: flex
                    flex-wrap: wrap

        &-best, &-cupis, &-salary
            & > .t-work-area-marginLeft > .t-it__container
                padding-left: 0

        &-best
            margin-bottom: 30px

        &-cupis__column
            margin: 30px 0 43px

        &-salary
            .t-it__text_small
                margin: 20px 0 50px

        &__left
            margin-top: 0
            .t-main-it-department-block__item._visible
                margin: 0 0 10px 0
                &:nth-child(odd)
                    margin-left: 16px

        &-vacancies
            padding: 40px 0 0 16px
            &__text
                font-size: 16px
            .t-it__right
                margin-top: 40px

    .t-banking
        &-product
            margin: 0 0 70px 0
            padding-left: 16px
            padding-bottom: 400px
            overflow: visible
            p
                padding-left: 0
                font-size: 14px
            &-logo
                width: calc(100% + 48px + 32px)
                padding-left: 16px
                margin-left: -32px
                overflow-x: auto
                -webkit-overflow-scrolling: touch
                touch-action: pan-x
                margin-bottom: 40px
                transform: translateX(-32px) !important
                &::-webkit-scrollbar
                    display: none
                    width: 0

                .t-it-product-logo__container
                    //min-width: 1350px
                    min-width: auto
                    flex-wrap: nowrap
                &__item
                    margin: 0 50px 60px 0
                    &:last-child
                        padding-right: 32px
                        min-width: calc(94px + 32px)
                    &.t-animate-item
                        transition-delay: 0s !important
            .t-background
                top: 0
                height: 150px
            &__container
                margin-top: 20px
                width: calc(100% - 16px)
            &__img
                width: calc(100% + 64px)
                padding-left: 32px
                margin-left: -32px
                overflow-x: auto
                -webkit-overflow-scrolling: touch
                touch-action: pan-x
                padding-bottom: 20px
                &::-webkit-scrollbar
                    display: none
                    width: 0

            &__item
                &:last-child
                    padding-right: 16px
