@font-face
    font-family: 'manrope'
    src: url('/fonts/manropebold.woff2') format('woff2'), url('/fonts/manropebold.woff') format('woff')
    font-weight: bold
    font-style: normal
@font-face
    font-family: 'manrope'
    src: url('/fonts/manropelight.woff2') format('woff2'), url('/fonts/manropelight.woff') format('woff')
    font-weight: 300
    font-style: normal

@font-face
    font-family: 'montserrat'
    src: url('/fonts/montserrat-bold.woff2') format('woff2'), url('/fonts/montserrat-bold.woff') format('woff')
    font-weight: bold
    font-style: normal
@font-face
    font-family: 'montserrat'
    src: url('/fonts/montserrat-semibold.woff2') format('woff2'), url('/fonts/montserrat-semibold.woff') format('woff')
    font-weight: 600
    font-style: normal
@font-face
    font-family: 'montserrat'
    src: url('/fonts/montserrat-medium.woff2') format('woff2'), url('/fonts/montserrat-medium.woff') format('woff')
    font-weight: 500
    font-style: normal
@font-face
    font-family: 'montserrat'
    src: url('/fonts/montserrat-regular.woff2') format('woff2'), url('/fonts/montserrat-regular.woff') format('woff')
    font-weight: normal
    font-style: normal
@font-face
    font-family: 'montserrat'
    src: url('/fonts/montserrat-italic.woff2') format('woff2'), url('/fonts/montserrat-italic.woff') format('woff')
    font-weight: normal
    font-style: italic
@font-face
    font-family: 'montserrat'
    src: url('/fonts/montserrat-light.woff2') format('woff2'), url('/fonts/montserrat-light.woff') format('woff')
    font-weight: 300
    font-style: normal
@font-face
    font-family: 'montserrat'
    src: url('/fonts/montserrat-lightitalic.woff2') format('woff2'), url('/fonts/montserrat-lightitalic.woff') format('woff')
    font-weight: 300
    font-style: italic


body
    min-width: 320px
    min-height: 100%
    margin: 0
    font-family: 'montserrat', Arial, sans-serif
    font-size: 16px
    color: $text-base
    line-height: 1.75
    font-weight: 300
    -webkit-font-smoothing: antialiased
    position: relative
    &.lock-scroll
        position: fixed
        width: 100%
        height: 100%

.t-preloader
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100vh
    background-color: #ffffff
    z-index: 100
    opacity: 1
    transition: opacity .4s
    pointer-events: auto
    &._close
        opacity: 0
        pointer-events: none

.load
    opacity: 0
    transition: opacity .5s .0s
    &._load
        opacity: 1
        transition: opacity .6s .0s

.t-animate
    opacity: 1 !important
    transition: all .5s
    transition-timing-function: cubic-bezier(.25,.1,.25,1)
    transform: translate3d(0, 0, 0)
    &.hide
        opacity: 0 !important
        transform: translate3d(0, 50px, 0)
        .t-animate-item
            opacity: 0
            transform: translate3d(0, 50px, 0)
    &-item
        //opacity: 0
        //transform: translate3d(0, 50px, 0)
        opacity: 1
        transform: translate3d(0, 0, 0)
        transition: all .4s !important
        transition-timing-function: cubic-bezier(.25,.1,.25,1) !important
        //animation: scrollAnimate .7s forwards
        @for $i from 1 through 50
            &:nth-child(#{$i})
                transition-delay: #{ .1 + 0.12*$i }s !important

//@keyframes scrollAnimate
//    0%
//        opacity: 0
//        transform: translate3d(0, 50px, 0)
//    100%
//        opacity: 1
//        transform: translate3d(0, 0, 0)


*
    box-sizing: border-box
    padding: 0
    margin: 0

a
    color: $text-base
    text-decoration: none
    &:not([class])
        color: #41a6e4
        &:hover, &:active
            color: #00beff
h1, h2, h3
    font-family: 'manrope', Arial, sans-serif
    font-weight: 300
    font-style: normal
    font-stretch: normal
h1
    font-size: 56px
    line-height: 1.43
h2
    font-size: 52px
    line-height: 1.54
    font-weight: 700
h3
    font-size: 48px
    line-height: 1.67
    font-weight: 700


.t-work-area
    padding: 0 80px 0 166px
    max-width: 1440px
    width: 100%
    margin: 0 auto
.t-work-area-fullRight
    padding: 0 0 0 166px
    margin: 0 0 0 calc((100% - 1440px)/2) !important
    max-width: calc((100% - 1440px)/2 + 1440px)
.t-work-area-marginLeft
    padding: 0 30px 0 424px
    max-width: 1440px
    width: 100%
    margin: 0 auto

.t-text
    font-size: 16px
    white-space: pre-line
    &_big
        font-size: 26px
        line-height: 1.62
        white-space: pre-line
    &_small
        font-size: 14px
        line-height: 2
        white-space: pre-line


.t-background
    background: radial-gradient(circle, $color-1 $max, transparent $max*1.3) 0 0, radial-gradient(circle, $color-1 $max, transparent $max*1.3) $size $size
    background-size: $big-size $big-size
    background-color: transparent
    background-repeat: repeat

.t-btn
    overflow: hidden
    padding: 19px 28px
    border-radius: 10px 10px 10px 10px
    cursor: pointer
    display: flex
    align-items: center
    position: relative
    width: fit-content
    font-family: 'montserrat', Arial, sans-serif
    box-shadow: 0 35px 25px -25px rgba($gray-base, .4)
    transition: all .3s !important
    z-index: 1
    //&:after
    //    content: ''
    //    position: absolute
    //    top: 5px
    //    left: 13px
    //    height: calc(100% + 5px)
    //    width: calc(100% - 26px)
    //    border-radius: 32.5px
    //    filter: blur(11px)
    //    opacity: .4
    //    background-color: $gray-base
    //    z-index: -2
    &:before
        content: ''
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: calc(100% + 80px)
        transform: translateX(-80px)
        transition: transform .3s ease-in
        z-index: -1
    span
        font-size: 18px
        font-weight: 600
        color: #ffffff
    &._img
        padding: 29px 78px 9px 15px
    &_img
        width: 100%
        height: 100%
        position: absolute
        top: 0
        left: 0
        background-repeat: no-repeat
        background-size: contain
        background-position: 100% 0
    &_number
        position: absolute
        right: 11px
        bottom: 10px
        opacity: .4
        font-size: 16px !important
        line-height: 1.5
    &._green
        &:before
            background: $gradient-green
        .t-btn_img
            background-image: url("/img/ico-it-btn.png")
    &._blue
        &:before
            background: $gradient-blue
        .t-btn_img
            background-image: url("/img/ico-fin-btn.png")
    //@media (min-width: 1024px)
    //    &:hover:before
    //        transform: translateX(0)
    &_arrow
        display: none
        min-width: 17px
        margin-left: 10px
        @media (max-width: 767px)
            &._mobile
                display: flex
    &:active
        transform: translateY(2px) !important
        box-shadow: 0 35px 20px -25px rgba($gray-base, .5) !important
    @media (min-width: 1024px)
        &:hover
            transform: translateY(-2px)
            box-shadow: 0 35px 30px -20px rgba($gray-base, .4)
            &:before
                transform: translateX(0)



.t-title
    position: relative
    width: fit-content
    img
        position: absolute
        top: -40px
        right: -140px
    &_mobile
        display: none

.t-column
    display: flex
    flex-direction: column
    &__item
        margin-bottom: 30px
        padding: 36px 25px 36px 42px
        max-width: 845px
        border: 1px solid rgba(#bdcfd1, .5)
        border-radius: 15px 45px 15px 15px
        background-color: #ffffff
        &:nth-child(even)
            margin-left: 40px
        &.t-shadow:after
            top: 65px
            left: 23px
            width: calc(100% - 46px)
            height: calc(100% - 50px)
    &__head
        margin-bottom: 28px
        display: flex
        align-items: flex-start
        img
            margin-top: 5px
    &__title
        margin-left: 17px
        font-size: 23px
        font-family: 'manrope', Arial, sans-serif
        font-weight: 300
    &__text
        font-size: 18px
        font-family: 'manrope', Arial, sans-serif
        font-weight: 300
        line-height: 1.89


.t-shadow
    box-shadow: 0 30px 30px -25px rgba($gray-base, .4)
    transition: box-shadow .4s
    &:active
        box-shadow: 0 28px 25px -22px rgba($gray-base, .5) !important
    @media (min-width: 1024px)
        &:hover
            box-shadow: 0 32px 35px -25px rgba($gray-base, .4)
    //position: relative
    //&:after
    //    content: ''
    //    position: absolute
    //    opacity: 0.5
    //    width: calc(100% - 46px)
    //    left: 23px
    //    height: calc(100% - 16px)
    //    bottom: -10px
    //    border-radius: 20px
    //    -webkit-filter: blur(21px)
    //    filter: blur(21px)
    //    background-color: #bdcfd1
    //    z-index: -1

.t-block
    display: flex
    flex-wrap: wrap
    align-items: center
    &__item
        border: 1px solid rgba(#bdcfd1, .5)
        border-radius: 16px
        background-color: #ffffff
        margin: 0 30px 40px 0
        &:nth-child(1), &:nth-child(5), &:nth-child(6)
            padding: 37px 32px 40px 41px
            font-size: 21px
            font-family: 'manrope', Arial, sans-serif
            font-weight: 300
            line-height: 1.48
        &:not(:nth-child(1), :nth-child(5), :nth-child(6))
            padding: 22px 24px 14px
            font-size: 14px
            line-height: 1.43
        &:nth-child(1)
            max-width: 281px
        &:nth-child(2)
            max-width: 241px
        &:nth-child(3)
            max-width: 427px
        &:nth-child(4)
            max-width: 452px
        &:nth-child(5)
            max-width: 583px
        &:nth-child(6)
            max-width: 555px
        &:nth-child(7)
            max-width: 290px
        &:nth-child(8)
            max-width: 427px
        &:nth-child(9)
            max-width: 316px
        &:nth-child(10)
            max-width: 284px
        &.t-shadow:after
            width: calc(100% - 46px)
            height: calc(100% - 16px)
            bottom: -10px
            left: 23px
    &._vacancies
        .t-block__item
            margin: 0 25px 38px 0
            &:nth-child(1), &:nth-child(5), &:nth-child(8)
                padding: 37px 32px 40px 41px
                font-size: 21px
                font-family: 'manrope', Arial, sans-serif
                font-weight: 300
                line-height: 1.48
            &:not(:nth-child(1), :nth-child(5), :nth-child(8))
                padding: 22px 24px 14px
                font-size: 14px
                line-height: 1.43
            &:nth-child(1)
                max-width: 281px
            &:nth-child(2)
                max-width: 452px
            &:nth-child(3)
                max-width: 427px
            &:nth-child(4)
                max-width: 290px
            &:nth-child(5)
                max-width: 560px
            &:nth-child(6)
                max-width: 280px
                margin-right: 0
            &:nth-child(7)
                max-width: 241px
            &:nth-child(8)
                max-width: 555px
            &:nth-child(9)
                max-width: 427px
            &:nth-child(10)
                max-width: 316px


._hidden
    height: 0 !important
    overflow: hidden !important
    padding: 0 !important
    margin: 0  !important
    opacity: 0
    //width: 0 !important

.t-container-order
    display: flex
    flex-direction: column
    position: relative
    z-index: 1
// бегущая строка
.marquee
    -webkit-animation: animMarquee 15s linear infinite
    animation: animMarquee 15s linear infinite

@keyframes animMarquee
    0%
        -webkit-transform: translateX(calc(100% - 160px))
        transform: translateX(calc(100% - 160px))
    100%
        -webkit-transform: translateX(calc(-100% - 170px))
        transform: translateX(calc(-100% - 170px))



.t-arrow
    width: 36px
    height: 36px
    background-color: #bdcfd1
    border-radius: 50%
    position: relative
    background-image: none !important
    opacity: 1 !important
    transform: rotate(180deg)
    span
        position: absolute
        top: 13px
        left: 13px
        width: 13px
        height: 3px
        background-color: #ffffff
        border-radius: 20px
        transform: rotate(45deg)
        &:after
            content: ''
            position: absolute
            top: 5px
            left: 5px
            width: 13px
            height: 3px
            background-color: #ffffff
            border-radius: 20px
            transform: rotate(90deg)



// анимация переходов страниц
.t-one
    position: absolute
    top: 0
    right: 0
    width: 5000px
    height: 100%
    padding: 0
    z-index: -3
    filter: grayscale(0)
    opacity: 1
    transform: translate3d(100%, -205px, 0) scale3d(1, 1, 1)
    -moz-transform: translate3d(100%, -205px, 0) scale3d(1, 1, 1)
    //transform: translateX(100%) translateY(-205px)
    transform-origin: 100% 0
    -moz-transform-origin: 100% 0
    transition: transform .8s, filter .8s, opacity .8s
    -moz-transition: transform .8s, filter .8s, opacity .8s
    &__top
        height: 204px
        border-bottom: 204px solid #00d300
        border-left: 204px solid transparent
    &__bottom
        margin-left: 172px
        width: calc(100% - 172px)
        height: calc(100% - 204px)
        background: linear-gradient(174deg, #00d300, #0CD3A9)
    &._animate-one
        //transform: translateX(calc(100% - 337px - (100vw - 1440px)/2)) translateY(0)
        transform: translate3d(calc(100% - 337px - (100vw - 1440px)/2), 0, 0) scale3d(1, 1, 1)
        -moz-transform: translate3d(calc(100% - 337px - (100vw - 1440px)/2), 0, 0) scale3d(1, 1, 1)
    &._animate-gray
        transform: translate3d(calc(15324px - (100vw - 1440px)/2), 0, 0) scale3d(3.4, 3.4, 1)
        -moz-transform: translate3d(calc(15324px - (100vw - 1440px)/2), 0, 0) scale3d(3.4, 3.4, 1)
        filter: grayscale(1)
        opacity: .13
        transition: transform .8s, filter .3s, opacity .3s
        -moz-transition: transform .8s, filter .3s, opacity .3s
    &._animate-hidden
        transition: transform .8s, filter .3s, opacity .3s
        -moz-transition: transform .8s, filter .3s, opacity .3s
        transform: translate3d(calc(15324px - (100vw - 1440px)/2), 0, 0) scale3d(3.7, 4.7, 1)
        -moz-transform: translate3d(calc(15324px - (100vw - 1440px)/2), 0, 0) scale3d(3.7, 4.7, 1)
        opacity: 0
    &._animate-disable
        transition: all 0s
        -moz-transition: all 0s


    &-container
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        overflow: hidden
        z-index: -20




@media (max-width: 1440px)
    .t-work-area-fullRight
        margin: 0 !important

    .t-one
        &._animate-one
            transform: translate3d(calc(100% - 337px), 0, 0) scale3d(1, 1, 1)
        &._animate-gray
            transform: translate3d(calc(15324px + (1440px - 100vw)), 0, 0) scale3d(3.4, 3.4, 1)


@media (max-width: 1400px)
    .t-work-area-marginLeft
        padding: 0 30px 0 320px

    .t-one
        &._animate-gray
            transform: translate3d(calc(15255px + (1440px - 100vw)), 0, 0) scale3d(3.4, 3.4, 1)


@media (max-width: 1320px)
    .t-work-area
        padding: 0 40px 0 80px
    .t-work-area-fullRight
        padding: 0 0 0 80px

    h1
        font-size: 52px
    h2
        font-size: 38px
    h3
        font-size: 36px

    .t-text
        &_big
            font-size: 24px

    .t-title
        img
            top: -30px
            right: -105px

    .t-block
        &._vacancies
            .t-block__item
                &:nth-child(5)
                    max-width: 535px
                &:nth-child(8)
                    max-width: 540px


@media (max-width: 1200px)
    .t-block
        &._vacancies
            .t-block__item
                &:nth-child(6)
                    margin-right: 25px


@media (max-width: 1000px)
    .t-work-area
        padding: 0 40px
    .t-work-area-fullRight
        padding: 0 0 0 40px
    .t-work-area-marginLeft
        padding: 0 30px 0 40px

    h1
        font-size: 36px
        line-height: normal
    h2
        font-size: 32px
        line-height: normal
    h3
        font-size: 30px
        line-height: normal

    .t-text
        &_big
            font-size: 20px

    .t-one
        transform: translate3d(100%, -75px, 0) scale3d(1, 1, 1)
        &__top
            height: 90px
            border-bottom: 90px solid #00d300
            border-left: 90px solid transparent
        &__bottom
            margin-left: 40px
            width: calc(100% - 40px)
            height: calc(100% - 90px)
        &._animate-one
            transform: translate3d(calc(100% - 77px), 0, 0) scale3d(1, 1, 1)
        &._animate-gray, &._animate-hidden, &.js-one-2, &.js-one-3
            //opacity: 0
            transform: translate3d(100%, -75px, 0) scale3d(1, 1, 1)

    .t-title
        &_mobile
            display: block
        &_hidden-mobile
            display: none



@media (max-width: 767px)

    body
        font-size: 14px

    h3
        font-size: 18px
        line-height: 31px

    .t-work-area
        padding: 0 16px
    .t-work-area-fullRight
        padding: 0 0 0 16px
    .t-work-area-marginLeft
        padding: 0 16px

    .t-background
        background: radial-gradient(circle, $color-1 $max-mobile, transparent $max-mobile*1.3) 0 0, radial-gradient(circle, $color-1 $max-mobile, transparent $max-mobile*1.3) $size-mobile $size-mobile
        background-size: $big-size-mobile $big-size-mobile
        background-color: transparent
        background-repeat: repeat

    .t-title
        max-width: 222px
        font-weight: 600
        img
            transform: scale(.65)
            top: -40px
            right: -80px
        //&_mobile
        //    display: block
        //&_hidden-mobile
        //    display: none

    .t-btn
        padding: 14px 10px
        span
            font-size: 14px

    .t-text
        &_big
            font-size: 16px

    .t-column
        &__item
            margin-bottom: 10px
            padding: 20px 18px 30px
            &:nth-child(even)
                margin-left: 0
        &__head
            margin-bottom: 15px
            //display: flex
            //align-items: center
            img
                width: 10px
                height: 16px
                object-fit: contain
                //margin-top: 0
        &__title
            margin-left: 8px
            font-size: 16px
        &__text
            font-size: 14px

    .t-block
        &__item
            margin: 0 0 10px
            width: 100% !important
            max-width: 100% !important
            padding: 20px 18px !important
            font-size: 14px !important
            font-family: 'manrope', Arial, sans-serif
            font-weight: 300
        &._vacancies
            .t-block__item
                margin: 0 0 10px 0
                font-family: 'manrope', Arial, sans-serif
                font-weight: 300
                &:nth-child(6)
                    margin-right: 0

    ._hidden
        width: auto !important

    .t-container-order
        overflow: hidden 
