footer
    padding: 140px 0 0
    order: 10
    overflow: hidden
    .t-work-area
        position: relative
    & > .t-background
        display: none

.t-footer
    &-vacancy
        margin-bottom: 70px
        max-width: 725px
        display: flex
        align-items: center
        .t-background
            height: 100px
            border-radius: 25px
            top: calc(50% - 50px)
        &__block
            margin-left: 85px
            display: block
        &__text
            margin-left: 10px
            max-width: 320px
            font-size: 14px
            line-height: 1.5

    &__container
        padding: 0 130px 0 85px
        position: relative
    &__text
        max-width: 825px
        margin-bottom: 99px
        font-size: 18px
        line-height: 1.89
    &__info
        display: flex
        flex-direction: column
        a
            //width: fit-content
            transition: transform .3s, opacity .3s
            &:active
                transform: scale(.95)
            @media (min-width: 1024px)
                &:hover
                    opacity: 1
    &__address
        font-weight: bold
    &__contact
        opacity: .6
    &__bottom
        padding-bottom: 46px
        margin-top: 53px
        display: flex
        justify-content: space-between
        color: #bdcfd1

    &__img
        position: absolute
        bottom: 0
        right: -40px
        img
            position: absolute
            bottom: 0
            right: -40px
    &__modal
        cursor: pointer
        margin-right: 50px
        transition: color .3s
        @media (min-width: 1024px)
            &:hover
                color: $text-base

    &-back
        display: none
        margin-bottom: 83px
        position: relative
        height: 80px
        align-items: center
        .t-background
            position: absolute
            top: 0
            left: 0
            height: 80px
            width: 100%
            z-index: -10
        &__link
            max-width: 168px
            width: 168px
            padding: 4px 18px 5px 5px
            background-color: #ffffff
            border-radius: 22px
            display: flex
            align-items: center
            justify-content: space-between
            font-weight: 400
            transition: transform .3s
            &:active
                transform: scale(.97) !important
            @media (min-width: 1024px)
                &:hover
                    transform: scale(1.04)
        &._mobile
            display: none !important


footer
    &._vacancies
        padding: 0
        .t-main-it-department-block, .t-footer__text, .t-footer__img, .t-footer-back__link ._back
            display: none
        .t-footer__container
            padding: 0 258px 0 0
        .t-footer-back
            display: flex
    &._it-vacancies, &._banking-vacancies
        display: flex
        align-items: flex-end
        max-width: 1440px
        margin: 0 auto
        width: 100%
        .t-work-area
            padding: 0 80px 0 85px
        .t-footer__img, .t-footer-back__link ._main
            display: none
        .t-footer-back
            display: flex
            .t-background
                display: none
        .t-footer-back__link
            width: 125px
            margin-right: 50px
        .t-footer__bottom
            justify-content: flex-start



@media (max-width: 1400px)
    footer
        &._it-vacancies, &._banking-vacancies
            .t-work-area
                padding: 0 80px 0 60px

    .t-footer
        &__container
            padding: 0 130px 0 60px
        &-vacancy__block
            margin-left: 60px


@media (max-width: 1280px)
    footer
        &._it-vacancies, &._banking-vacancies
            .t-work-area
                padding: 0 80px 0 20px

    .t-footer
        &-vacancy
            max-width: 560px


@media (max-width: 1000px)
    footer
        &._it-vacancies, &._banking-vacancies
            .t-footer-back__link
                margin-right: 0
            .t-footer
                &__container
                    padding: 0 0 0 40px
            .t-work-area
                padding: 0 40px 0 20px

    .t-footer
        &__container
            padding: 0 170px 0 40px
        &-vacancy__block
            margin-left: 40px
            margin-right: 0 !important
        &__text
            font-size: 14px


@media (max-width: 767px)
    footer
        padding: 65px 0 0
        & > .t-work-area
            display: flex
            flex-direction: column
        &._vacancies
            .t-footer__container
                padding: 24px 0 0 32px
                background-color: #ffffff
                //z-index: -25
                max-width: calc(100% - 5px)
            .t-footer__bottom
                span:last-child
                    width: 100%
                    margin-left: 0
                    border-top: 1px solid rgba(#bdcfd1, .29)
        &._it-vacancies, &._banking-vacancies
            padding-top: 180px
            flex-direction: column
            position: relative
            .t-work-area
                padding: 0 16px
            .t-footer
                &__container
                    padding: 0 16px
                    &._mobile
                        padding: 24px 32px 0
                &__img
                    display: block
                &-back
                    width: 100%
                    height: 54px
                    margin-bottom: 38px
                    &__link
                        width: 100%
                        margin: 0 auto
                        border: 1px solid rgba(#bdcfd1, .4)
                        background-color: #ffffff
                    &._mobile
                        display: block !important
                    &._mobile-hidden
                        display: none !important
            & > .t-background
                display: block
                width: 100%
                height: 60px
                position: absolute
                top: 70px
                z-index: 1

        .t-main-it-department-block__item._visible
            margin: 0

    .t-footer
        &-vacancy
            margin-bottom: 55px
            flex-direction: column
            .t-background
                height: 150px
            &__text
                max-width: 236px
                font-size: 13px
                margin: 20px 0
                text-align: center
        &__text
            order: -1
            margin-bottom: 65px
        &__container
            padding: 0 16px
            &._mobile
                padding: 24px 32px 0
                width: calc(100% + 32px)
                margin-left: -16px
                background-color: rgba(#bdcfd1, .2)
        &__img
            right: 0
            top: 0
            img:first-child
                display: none
            img:last-child
                width: 71px
                height: 145px
                bottom: 186px
                right: 0
                top: 0
                filter: brightness(0) invert(1)

        &__address
            margin-bottom: 10px
            order: -2
            max-width: 205px

        &__contact
            &:nth-child(3)
                order: -1
                margin-bottom: 25px
                max-width: 160px
                color: #78979a
            &:not(:nth-child(3))
                padding: 12px 0
                border-top: 1px solid rgba(#bdcfd1, .29)
                font-size: 14px
                opacity: 1

        &__bottom
            margin-top: 0
            padding-bottom: 0
            flex-direction: column
            span:last-child
                padding: 8px
                background-color: #ffffff
                width: calc(100% + 64px)
                margin-left: -32px
                display: flex
                justify-content: center
                font-size: 12px
        &__modal
            width: 100%
            margin: 0
            color: $text-base
            padding: 14px 0
            border-top: 1px solid rgba(#bdcfd1, .29)

        &-back
            //margin-top: 90px
            margin-bottom: 10px
            .t-background, .t-arrow
                display: none
            &__link
                border-radius: 10px
                background-color: rgba(#bdcfd1, .26)
                padding: 14px 68px
                max-width: 320px
                width: calc(100% - 36px)
                justify-content: center
            .t-btn_arrow
                transform: rotate(180deg)
                filter: brightness(1) invert(1)
                margin-left: 0
                margin-right: 7px

