.t-vacancies
    padding-top: 204px
    margin-bottom: 132px
    &__container
        margin-bottom: 120px
        position: relative

    &-tags
        display: flex
        flex-wrap: wrap
        max-width: 450px
        margin-bottom: 57px
        &__item
            margin: 0 10px 10px 0
            padding: 13px 25px
            color: $gray-base
            border-radius: 4px
            font-weight: 400
            cursor: pointer
            transform: translateY(0)
            transition: transform .3s, color .3s, border-radius .3s
            position: relative
            overflow: hidden
            z-index: 10
            -webkit-mask-image: -webkit-radial-gradient(white, black)
            &:before
                content: ''
                position: absolute
                top: 0
                left: 0
                height: 100%
                width: calc(100% + 80px)
                background: linear-gradient(75deg, #00d300, #0fd3d3)
                filter: grayscale(1)
                -webkit-filter: grayscale(1)
                opacity: .1
                transform: translateX(-80px)
                transition: transform .3s ease-in, filter .3s, opacity .3s
                z-index: -1
            @media (min-width: 1024px)
                &:hover
                    transform: translateY(-2px)
                    &:before
                        transform: translateX(0)
            &._active
                color: #ffffff
                &:before
                    filter: grayscale(0)
                    -webkit-filter: grayscale(0)
                    opacity: 1 !important

    &-block
        position: relative
        .t-background
            position: absolute
            top: calc(50% - calc(100% - 168px)/2)
            height: calc(100% - 198px)
            min-height: 130px
            width: 100%
            z-index: -10
        &__container
            display: flex
            flex-wrap: wrap
            max-width: 1300px
            .t-main-it-department-block__item
                margin-bottom: 28px
                overflow: hidden
                //&:nth-child(6n + 4)
                    margin-left: 40px !important
                //&:nth-child(3n)
                    margin-right: 0 !important
                &._all-vacancies
                    display: block
                    max-width: 289px
                    border-radius: 16px
                    .t-main-it-department-block__title
                        margin-top: 37px
                        max-width: 200px
                        margin-bottom: 29px
            .t-main-it-department-block__title
                max-width: 260px
                font-size: 21px
                line-height: 1.48
        &__hh
            padding: 35px 38px
            font-size: 30px
            font-weight: bold
            font-family: 'manrope', Arial, sans-serif
            line-height: 1
            color: #ffffff
            position: absolute
            top: -49px
            right: -40px
            width: 136px
            height: 136px
            background-image: linear-gradient(144deg, #ff8900, #ff0061)
            border-radius: 50%
            display: flex
            align-items: flex-end

    &__quote
        .t-main-quote__line
            right: calc(100% - ((100% - 1440px)/2) - 0px - 580px)
        .t-main-quote__container
            padding-left: 325px

    &-it
        margin-left: 0
    &-bank
        //padding-left: 446px
        margin-bottom: 43px

    &__btn
        .t-main-it__btn
            margin-bottom: 30px
        .t-main-bank__btn
            margin-bottom: 110px



@media (max-width: 1400px)
    .t-vacancies
        &-block
            &__container
                .t-main-it-department-block__item
                    width: 338px

@media (max-width: 1220px)
    .t-vacancies
        &-block
            &__container
                .t-main-it-department-block__item
                    width: 265px
                .t-main-it-department-block__text
                    font-size: 16px


@media (max-width: 1000px)
    .t-vacancies
        &-block
            &__container
                .t-main-it-department-block__item
                    width: 225px
                    &._all-vacancies
                        .t-main-it-department-block__title
                            margin-top: 38px
                            max-width: 180px
                            margin-bottom: 10px
                .t-main-it-department-block__title
                    font-size: 18px
                .t-main-it-department-block__text
                    font-size: 13px
                    line-height: 33px

            &__hh
                transform: scale(.65)
        &__quote
            .t-main-quote__line
                right: 0
            .t-main-quote__container
                padding-left: 0
        &__btn
            margin-top: 10px
            max-width: calc(100% - 50px)
            .t-main-bank__btn
                margin-bottom: 90px


@media (max-width: 840px)
    .t-vacancies
        &-block
            &__container
                .t-main-it-department-block__item
                    width: 200px


@media (max-width: 767px)
    .t-vacancies
        padding-top: 107px
        margin-bottom: 60px
        &-block
            &__container
                padding-right: 16px
                .t-main-it-department-block__item
                    width: 100%
                    max-width: 360px
                    margin-right: 16px !important
                    margin-bottom: 10px
                    margin-left: 0 !important
                    &._all-vacancies
                        max-width: 360px
                & > .t-animate-item
                    width: 100%

        &-bank
            padding-left: 16px
            margin-bottom: 10px

        &__container
            margin-right: 36px
            margin-bottom: 44px

        &-tags
            max-width: calc(100% - 36px)

        &__quote
            .t-main-it-department__content
                padding: 60px 36px 0 16px

