.t-modal
    display: flex
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100vh
    z-index: 25
    justify-content: center
    align-items: center
    pointer-events: none
    opacity: 0
    transition: opacity .4s
    &._open
        opacity: 1
        pointer-events: auto
    &.t-background
        background-color: rgba(#ffffff, .9)
    &__form
        width: 100%
        max-width: 760px
        opacity: 1
        transition: opacity .4s
        .t-btn
            margin: 0 auto
            padding: 19px 46px
            font-size: 18px
            color: #ffffff
            outline: none
            border: none
            z-index: 1
            &._disabled
                pointer-events: none
                &:before
                    background: linear-gradient(253deg, #bdbdbd, #d4d4d4)
        &._hidden-modal
            opacity: 0
            pointer-events: none
    &__window
        padding: 26px 33px
        border: 1px solid rgba(#bdcfd1, .5)
        border-radius: 16px 35px 16px 16px
        background-color: #ffffff
        position: relative
    &__close
        top: 17px
        right: 17px
        width: 31px
        height: 31px
        background-color: #e1e1e1
        border-radius: 50%
        position: absolute
        cursor: pointer
        transition: transform .3s
        span
            position: absolute
            top: 14px
            left: 8px
            width: 15px
            height: 3px
            background-color: #ffffff
            border-radius: 20px
            transform: rotate(45deg)
            &:after
                content: ''
                position: absolute
                top: 0
                left: 0
                width: 15px
                height: 3px
                background-color: #ffffff
                border-radius: 20px
                transform: rotate(90deg)
        @media (min-width: 1024px)
            &:hover
                transform: scale(1.1)
    &__title
        font-size: 28px
        font-family: 'manrope', Arial, sans-serif
        font-weight: 300
    &__text
        margin: 10px 0
        border: none
        width: 100%
        height: 230px
        line-height: 1.44
        font-size: 16px
        outline: none
        resize: none
        &:focus
            &::placeholder
                opacity: 0
        &::placeholder
            font-family: 'montserrat', Arial, sans-serif
            transition: opacity .3s
            color: #bdcfd1
    &__email
        margin: 80px auto 40px
        max-width: 440px
        border-bottom: 1px solid $text-base
        display: flex
        input
            padding: 12px 0
            margin-right: 20px
            font-size: 18px
            width: 100%
            border: none
            background-color: transparent
            outline: none
            &:focus
                &::placeholder
                    opacity: 0
            &::placeholder
                font-family: 'montserrat', Arial, sans-serif
                transition: opacity .3s
                color: $text-base
            //&:-webkit-autofill
            //    background-color: transparent !important
        &._border-error
            border-bottom: 1px solid #ff0000

    &__checkbox
        margin-bottom: 20px
        label
            padding-left: 36px
            position: relative
            display: flex
            align-items: center
            cursor: pointer
            transition: color .2s
            span 
                margin-left: 20px
            &:before
                content: ''
                position: absolute
                left: 0
                top: 50%
                transform: translateY(-50%)
                width: 32px
                height: 32px
                border: 1px solid #00b500
                border-radius: 4px
                transition: border .2s
            svg
                position: absolute
                left: 9px
                top: 50%
                transform: translateY(-50%)
                z-index: 1
                opacity: 0
                transition: opacity .2s
                width: 18px
                height: 20px
        
        input
            display: none
            &:checked + label
                svg
                    opacity: 1

        &._border-error-checkbox
            label:before 
                border: 1px solid #ff0000

    &__error
        display: flex
        color: #ff0000
        align-items: center
        min-width: 150px
        opacity: 0
        pointer-events: none
        transition: opacity .3s
        &-mark
            width: 20px
            height: 20px
            background-color: #ff0000
            color: #ffffff
            border-radius: 50%
            margin-right: 8px
            display: flex
            align-items: center
            justify-content: center
            font-weight: bold
            font-size: 18px
        &._open
            opacity: 1
            pointer-events: auto

    &-success
        position: absolute
        opacity: 0
        transition: opacity .4s .4s
        max-width: 400px
        pointer-events: none
        &._open
            opacity: 1
            pointer-events: auto
        .t-modal__title
            font-size: 36px
        &__window
            width: 100%
            padding: 40px 42px 50px
            //border: 1px solid rgba(#bdcfd1, .5)
            border-radius: 16px 35px 16px 16px
            background-color: #ffffff
            .t-btn
                margin-top: 60px
                border-radius: 16px
                width: 100%
                display: flex
                justify-content: center
                color: #ffffff
                font-weight: 600
                font-size: 18px
        &__img
            position: absolute
            top: 0
            right: 0
            width: 180px
            height: 115px
            background: url("/img/serpantin-5.png") no-repeat 0 100%
        &__text
            margin-top: 20px
        &__subtext
            margin: 50px 0 43px 42px
            max-width: 245px
            font-size: 14px
            line-height: 1.5
        &__link
            margin-left: 42px
            max-width: 323px
            width: 100%
            background: linear-gradient(107deg, #ff8900, #ff0061)
            padding: 18px 26px 20px
            font-size: 18px
            display: flex
            align-items: center
            span
                font-family: 'manrope', Arial, sans-serif
                font-weight: 300
            img
                margin-left: 20px
        &__hh
            font-size: 28px !important
            margin-left: auto
            line-height: 1

@media (max-width: 1070px)
    .t-modal
        align-items: flex-start
        padding-top: 20px
        &__text
            height: 100px
        &__email
            margin: 30px auto
        &-success
            height: 100vh
            &__window
                margin: 20px auto 0
                width: calc(100% - 32px)
                border: 1px solid rgba(#bdcfd1, .5)
                padding: 28px 18px 23px
                .t-btn
                    margin-top: 20px
                    font-size: 14px
                .t-modal__title
                    font-size: 22px
            &__subtext
                margin: 27px auto 20px
                color: #78979a
            &__link
                margin: 0 auto
                width: calc(100% - 64px)
                padding: 12px 26px 12px
            &__img
                transform: scale(.8)
                transform-origin: 100% 0

@media (max-width: 767px)
    .t-modal
        padding-top: 0
        &__window
            padding: 60px 16px 20px
            border: none
            background-color: transparent
        &__close
            background-color: #ffffff
            box-shadow: 1px 5px 10px 2px rgba(#bdcfd1, .5)
            span, span:after
                background-color: #bdcfd1
        &__form
            height: 100vh
            background-color: #ffffff
            .t-btn
                margin: 0 auto
                width: calc(100% - 32px)
                max-width: 400px
                padding: 14px 46px
                font-size: 14px
                display: flex
                justify-content: center
        &__text
            padding: 0 10px
            margin: 20px 0
            height: 100px
            white-space: normal
        &__email
            margin: 30px 16px 54px
            border-bottom: 1px solid #bdcfd1
            input
                padding: 10px
                font-size: 14px
                margin-right: 0
                //&::placeholder
                //    color: #bdcfd1
            .t-modal__error
                display: none
        &__error
            align-items: flex-start

        &-success
            background-color: #ffffff

        &__checkbox
            padding: 0 16px 20px            