$text-base: #000000
$text-add: #5a878b

$gradient-green: linear-gradient(251deg, #00db00, #00b500)
$gradient-blue: linear-gradient(256deg, #13e0e0, #08bdbd)

$gray-base: #a3b6b8



// для паттерна с точками (t-background)
$color-1: rgba(0, 0, 0, .06)
$size: .251em
$big-size: $size*2
$min: 0
$max: $size*.3

// для мобилки
$size-mobile: .3em
$big-size-mobile: $size-mobile*2
$max-mobile: $size-mobile*.3