.t-main
    padding-top: 296px

    .t-btn
        color: #fff !important
        font-weight: 600

    &-top
        width: 100%
        height: 361px
        position: absolute
        top: 0
        left: 0
        overflow: hidden

    &-slider
        position: absolute
        top: 0
        right: 0
        max-width: 841px
        max-height: 100%
        clip-path: polygon(0 100%, calc(100% - 57.3%) 0, 100% 0, 57.3% 100%)
        -webkit-clip-path: polygon(0 100%, calc(100% - 57.3%) 0, 100% 0, 57.3% 100%)
        .swiper-container
            height: 361px
            cursor: grab
        &__pattern
            width: 434px
            height: 361px
            position: absolute
            top: 0
            right: -76px
            clip-path: polygon(0 100%, calc(100% - 17.3%) 0, 100% 0, 17.3% 100%)
            -webkit-clip-path: polygon(0 100%, calc(100% - 17.3%) 0, 100% 0, 17.3% 100%)
        img
            max-height: 100%
            max-width: 100%
            object-fit: cover
            clip-path: polygon(0 100%, calc(100% - 57.3%) 0, 100% 0, 57.3% 100%)
            -webkit-clip-path: polygon(0 100%, calc(100% - 57.3%) 0, 100% 0, 57.3% 100%)
    &-swiper-pagination
        margin-top: -6px
        height: 6px
        width: 500px
        & .swiper-pagination-progressbar-fill
            background: $gradient-green !important
            clip-path: polygon(0 100%, 0 0, 100% 0, 95.5% 100%)
            -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 95.5% 100%)

    &-container
        max-width: 815px
        margin-bottom: 188px
        h1
            margin-bottom: 45px
        .t-title img
            top: -12px
            right: -40px
        .t-main-cupis
            @media (max-width: 920px)
                &__text
                    font-size: 10px

    &-btn
        margin-top: 59px
        margin-bottom: 152px
        display: flex
        &__left
            margin-right: 20px

        .t-btn
            padding: 19px 28px
            justify-content: center
            line-height: 22px

        @media (max-width: 920px)
            .t-btn
                padding: 14px 0
            ._blue
                width: 158px

            ._green
                width: 118px

    &-cupis
        display: flex
        align-items: flex-start
        position: relative
        &__question
            margin-top: 2px
            margin-right: 11px
            //background: $gray-base
            //border-radius: 50%
            //width: 16px
            //min-width: 16px
            //height: 16px
            //display: flex
            //justify-content: center
            //align-items: center
            //color: #ffffff
            //font-size: 10.5px
            //font-weight: 600
            //line-height: 16px
        &__text
            max-width: 304px
            font-size: 12px
            font-weight: 400
            line-height: 1.58
            color: $gray-base
            @media (max-width: 920px)
                color: #78979A
                font-weight: 500

        .t-background
            width: 100%
            height: 100%
            position: absolute
            top: 0
            left: 0
            display: none
            @media (max-width: 920px)
                display: block
    &-it
        position: relative
        &__container
            margin-left: 74px
            max-width: 587px
            margin-top: 14px
        &__text
            margin-bottom: 40px
            white-space: normal !important
            @media (max-width: 920px)
                font-size: 13px
                margin-bottom: 0
        &__img
            width: 191px
            height: 191px
            border-radius: 50%
            position: absolute
            top: 20px
            right: 130px
            &-1
                position: absolute
                top: -115px
                left: -5px
            &-2
                position: absolute
                top: -55px
                right: 40px
        &__btn
            margin-bottom: 188px

    &-bank
        padding-left: 448px
        position: relative
        &__img
            width: 191px
            height: 191px
            border-radius: 50%
            position: absolute
            top: 157px
            left: 48px
            &-1, &-2, &-3, &-4
                position: absolute
                bottom: -19px
                width: 20px
                background: linear-gradient(2deg, #00d300, #0fd3d3)
            &-1
                left: -10px
                height: 56px
            &-2
                left: 36px
                height: 97px
            &-3
                left: 82px
                height: 166px
            &-4
                left: 128px
                height: 233px
            &-2, &-4
                &:after
                    content: ''
                    position: absolute
                    width: 100%
                    height: 100%
                    background-color: rgba(0, 0, 0, 0.16)
        &__btn
            margin-left: 448px
            margin-bottom: 80px
            white-space: nowrap
            span
                white-space: nowrap

    &-quote
        position: relative
        &__container
            margin-bottom: 100px
            padding-left: 448px
        &__title
            margin-bottom: 38px
            display: flex
            align-items: center
        &__face
            width: 112px
            height: 113px
            margin-right: 40px
            img
                max-width: 100%
                max-height: 100%
                object-fit: contain
        &__name
            font-size: 23px
            line-height: 1.35
            font-family: 'manrope', Arial, sans-serif
            font-weight: 300
        &__position
            font-size: 14px
            line-height: 2
            color: #78979a
        &__text
            padding-left: 155px
            padding-right: 5px
            white-space: pre-line
            font-style: italic
            color: $text-add
            line-height: 1.88
        &__line
            position: absolute
            top: 180px
            right: calc(100% - ((100% - 1440px)/2) - 110px - 620px)
            height: 32px
            width: calc(100% - (100% - ((100% - 1440px)/2) - 110px - 620px))
            background: url("/img/wave.png") no-repeat
            &._bottom
                top: auto
                bottom: 60px

    &-block
        margin-top: 60px
        padding-top: 67px
        position: relative
        order: 9
        .t-background
            height: calc(100% - 188px)
            width: 100%
            position: absolute
            top: 0
            left: 0
            z-index: -1
        &__title
            margin: 0 0 22px
            font-size: 18px
            font-family: 'manrope', Arial, sans-serif
            font-weight: 300
            line-height: 4.67
            letter-spacing: 7.2px
            text-align: center
            text-transform: uppercase
        &__container
            display: flex
            flex-wrap: wrap
            justify-content: center
            max-width: 764px
            margin: 0 auto 150px
        &__item
            margin-bottom: 19px
            padding: 19px
            width: 241px
            border: 1px solid rgba(#bdcfd1, .5)
            border-radius: 30px 10px 10px 10px
            background-color: #ffffff
            cursor: pointer
            transition: transform .3s
            &:not(:nth-child(3n))
                margin-right: 20px
            &:last-child
                margin-right: 0
            &.t-shadow:after
                top: 18px
                left: 14px
                height: calc(100% - 13px)
                width: calc(100% - 28px)
            img
                margin: -16px 0 0 -28px
                max-width: 99px
                max-height: 96px
                pointer-events: none
            &._all
                display: flex
                align-items: center
                height: 91px
                img
                    margin: 7px 0 0 -28px
                .t-main-block__subtitle
                    margin-top: 0
            &:active
                transform: translateY(2px) !important
            @media (min-width: 1024px)
                &:hover
                    transform: translateY(-2px)
        &__subtitle
            margin-top: -16px
            font-size: 18px
            font-weight: bold

    &-company
        padding: 120px 0 0
        //opacity: 0
        overflow: hidden
        transition: opacity .7s
        .t-main-cupis
            max-width: 290px
            margin-bottom: 32px
            margin-left: auto
        .t-main-company-swiper2
            margin-bottom: 280px
            @media (min-width: 1024px)
                margin-left: 85px

        &__text
            margin: 36px 0 35px 86px
            max-width: 675px
            font-weight: 300

        &-swiper
            padding-left: calc(86px + 166px)
            padding-bottom: 40px
            cursor: grab
            width: 100%
            max-width: calc(100% - (100% - 1440px)/2)
            overflow: visible !important
            margin: 0 0 0 calc((100% - 1440px)/2)
            display: flex
            .swiper-slide
                //width: fit-content !important
                //width: 500px !important
                &:last-child
                    margin-right: 166px !important
            .swiper-wrapper
                width: fit-content !important
                //margin-left: 166px

        &-slider
            position: relative
            margin-bottom: 130px

            .t-background
                position: absolute
                top: calc(50% - 65px)
                left: 0
                height: 130px
                width: 100%
                z-index: -1
            &__item
                width: 100%
                max-width: 100%
                padding: 20px 20px 40px 40px
                border: 1px solid rgba(#bdcfd1, .5)
                border-radius: 15px 30px 15px 15px
                background-color: #ffffff
                position: relative
                overflow: hidden
                &:hover
                    .t-main-company-slider__line
                        opacity: 1
                        transition: opacity .3s
                        &:after
                            transform: translateX(0)
            &__container
                margin-bottom: 25px
                display: flex
                justify-content: space-between
                align-items: flex-end
                span
                    background: linear-gradient(67deg, #00d300, #0fd3d3)
                    font-size: 21px
                    line-height: 1.48
                    font-family: 'manrope', Arial, sans-serif
                    font-weight: 300
                    -webkit-background-clip: text
                    -webkit-text-fill-color: transparent
            &__number
                width: 40px
                height: 40px
                background-color: rgba(#bdcfd1, .26)
                color: $gray-base
                border-radius: 50%
                display: flex
                align-items: center
                justify-content: center
            &__text
                font-size: 21px
                font-family: 'manrope', Arial, sans-serif
                font-weight: 300
                line-height: 1.48
            &__line
                position: absolute
                bottom: 0
                left: 0
                width: 100%
                height: 7px
                background-color: rgba(#bdcfd1, .26)
                opacity: 0
                transition: opacity .8s
                &:after
                    content: ''
                    position: absolute
                    bottom: 0
                    left: 0
                    transform: translateX(-100%)
                    transition: transform .7s
                    width: calc(100% + 10px)
                    height: 7px
                    background: linear-gradient(269deg, #00db00, #00b500)
                    clip-path: polygon(0 100%, 0 0, 100% 0, 97.5% 100%)
                    -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 97.5% 100%)

            .t-swiper-nav
                position: absolute
                bottom: -115px
                right: 80px
                height: 35px
                width: 100px
                display: flex
                justify-content: space-between
                align-items: center
            .swiper-button-prev, .swiper-button-next
                outline: none
                transition: transform .3s
                &.swiper-button-disabled
                    background-color: transparent
                    opacity: 1 !important
                    span, span:after
                        background-color: #bdcfd1
            .swiper-button-prev
                &:active
                    transform: scale(.95) rotate(180deg) !important
                @media (min-width: 1024px)
                    &:hover
                        transform: scale(1.1) rotate(180deg)
            .swiper-button-next
                transform: rotate(360deg)
                &:active
                    transform: scale(.9) rotate(360deg) !important
                @media (min-width: 1024px)
                    &:hover
                        transform: scale(1.1) rotate(360deg)

        &__quote
            padding-top: 100px

        &-swiper2
            padding: 30px 0 0 86px
            margin-bottom: 255px
            position: relative
            .swiper-slide
                //width: 478px !important
                //&:last-child
                    //margin-right: 185px !important
                    //width: 100% !important
            .t-swiper-nav
                position: absolute
                bottom: -115px
                right: 80px
                height: 35px
                width: 100px
                display: flex
                justify-content: space-between
                align-items: center
            .swiper-button-prev, .swiper-button-next
                outline: none
                transition: transform .3s
                &.swiper-button-disabled
                    background-color: transparent
                    opacity: 1 !important
                    span, span:after
                        background-color: #bdcfd1
            .swiper-button-prev
                &:active
                    transform: scale(.95) rotate(180deg) !important
                @media (min-width: 1024px)
                    &:hover
                        transform: scale(1.1) rotate(180deg)
            .swiper-button-next
                transform: rotate(360deg)
                &:active
                    transform: scale(.9) rotate(360deg) !important
                @media (min-width: 1024px)
                    &:hover
                        transform: scale(1.1) rotate(360deg)
            &__container
                //margin-bottom: 120px
                position: relative
                cursor: grab
            &__img, &__img2
                position: absolute
                top: 0
                left: 0
                width: 27px
                height: 20px
                background: linear-gradient(54deg, #00d300, #0fd3d3)
                clip-path: polygon(0 100%, calc(100% - 30.3%) 0, 100% 0, 30.3% 100%)
                -webkit-clip-path: polygon(0 100%, calc(100% - 30.3%) 0, 100% 0, 30.3% 100%)
            &__img2
                left: 16px
            &__name
                font-size: 23px
                font-family: 'manrope', Arial, sans-serif
                font-weight: 300
                line-height: 1.35
            &__position
                font-size: 14px
            &__text
                margin-top: 20px
                font-size: 18px
                line-height: 1.89
                color: $text-add
                max-width: 478px
            &__pagination
                margin-top: 22px
                display: none
                //display: flex
                justify-content: flex-start
                .swiper-pagination-bullet
                    bottom: -25px
                    width: 27px
                    height: 6px
                    clip-path: polygon(0 100%, calc(100% - 83.5%) 0, 100% 0, 83.5% 100%)
                    -webkit-clip-path: polygon(0 100%, calc(100% - 83.5%) 0, 100% 0, 83.5% 100%)
                    background-color: #bdcfd1
                    border-radius: 0 !important
                    margin-right: 5px
                    transition: all .3s
                    &.swiper-pagination-bullet-active
                        width: 57px
                        background: linear-gradient(84deg, #00d300, #0fd3d3)
                        clip-path: polygon(0 100%, calc(100% - 92.5%) 0, 100% 0, 92.5% 100%)
                        -webkit-clip-path: polygon(0 100%, calc(100% - 92.5%) 0, 100% 0, 92.5% 100%)

    &-str
        padding: 38px 0 35px
        height: 102px
        overflow: hidden
        &__content
            font-size: 21px
            font-family: 'manrope', Arial, sans-serif
            font-weight: 300
            line-height: 1.36
            text-transform: uppercase
            white-space: nowrap
            display: flex
            width: fit-content
            p
                margin-right: 40px

    &-product
        padding-top: 170px
        opacity: 0
        transition: opacity .7s
        &__block
            padding: 36px 40px 70px
            //margin-bottom: 100px
            max-width: 655px
            border: 1px solid rgba(#bdcfd1, .5)
            border-radius: 15px 30px 15px 15px
        &__subtitle, &__text
            font-size: 21px
            font-family: 'manrope', Arial, sans-serif
            font-weight: 300
            line-height: 1.48
        &__subtitle
            margin-bottom: 27px
            color: #bdcfd1
        &__img
            margin-top: 20px
            margin-bottom: 140px
            position: relative
            overflow: hidden
            .t-background
                height: 290px
                width: 100%
                position: absolute
                top: calc(50% - 145px)
                left: 0
                z-index: -1
        &__container
            display: flex
            align-items: flex-end
            max-height: 732px
            img
                margin-right: 20px
                max-height: 732px
                object-fit: contain
            &.t-work-area-fullRight
                display: grid
                grid-template-columns: 20% 20% calc( 60% - 40px )
                img
                    max-width: 100%

    &-it-department
        padding-top: 160px
        opacity: 0
        transition: opacity .7s
        &::-webkit-scrollbar
            display: none

        &-block
            //margin-bottom: 120px
            position: relative
            .t-background
                height: 130px
                width: 100%
                position: absolute
                top: calc(50% - 65px)
                left: 0
                z-index: -1
            .t-work-area
                padding-right: 0
            &__container
                display: flex
                padding-left: 86px
                overflow-x: auto
                padding-top: 5px
                -ms-overflow-style: none
                scrollbar-width: none
                min-height: 200px

                &::-webkit-scrollbar
                    display: none

            &__item
                display: none
                //width: 0
                //height: 0
                //overflow: hidden
                //padding: 0
                //margin: 0
                width: 370px
                height: 179px
                padding: 26px 0 24px 32px
                border: 1px solid rgba(#bdcfd1, .5)
                border-radius: 15px 16px 35px 15px
                background-color: #ffffff
                position: relative
                //transform: translateY(50px)
                //opacity: 0
                transition: all .3s
                //pointer-events: none
                img
                    position: absolute
                    bottom: -10px
                    right: 0
                &:active
                    transform: scale(.99) translateY(2px) !important
                @media (min-width: 1024px)
                    &:hover
                        transform: scale(1.02) translateY(-2px)

                @media (max-width: 767px)
                    height: 122px

                &._visible
                    display: flex
                    flex-direction: column
                    //width: 375px
                    //padding: 26px 0 24px 32px
                    //height: auto
                    //opacity: 1
                    //overflow: auto
                    //transform: translateY(0)
                    //pointer-events: auto
                    //&_margin
                    //&:not(:last-child)
                    margin-right: 28px
                &._all
                    min-width: 252px
                    width: 252px
                    cursor: pointer
                    .t-main-it-department-block__img
                        height: 100%
                        width: 100%
                        background-repeat: no-repeat
                        background-size: contain
                        background-position: 100% 0
                    .t-main-it-department-block__title
                        height: auto
                        margin: 35px 0 5px
                        max-width: 145px
                        color: #ffffff
                    .t-main-it-department-block__text
                        color: #ffffff
                        font-size: 16px
                    &-green
                        background: $gradient-green
                        .t-main-it-department-block__img
                            background-image: url("/img/ico-one-card-more2.png")
                        &.t-shadow:after
                            background-color: #00ce00

                        @media (max-width: 920px)
                            margin-left: 0 !important
                            margin-right: 0
                    &-blue
                        background: $gradient-blue
                        .t-main-it-department-block__img
                            background-image: url("/img/ico-one-card-more.png")
                        &.t-shadow:after
                            background-color: #10d8d8

                    @media (max-width: 920px)
                        min-width: 271px
                        width: 271px

            &__img
                position: absolute
                top: 0
                right: 0
            &__title
                margin-bottom: auto
                font-size: 24px
                font-family: 'manrope', Arial, sans-serif
                font-weight: 300
                line-height: 1.33
                span
                    overflow: hidden
                    text-overflow: ellipsis
                    display: -webkit-box
                    display: -moz-box
                    -moz-box-orient: vertical
                    -webkit-line-clamp: 3
                    -webkit-box-orient: vertical
                    line-clamp: 3
                    box-orient: vertical
                    @media (max-width: 767px)
                        -webkit-line-clamp: 2
                        line-clamp: 2

            &__text
                padding-right: 64px
                font-size: 20px
                font-family: 'manrope', Arial, sans-serif
                font-weight: 300
                line-height: 1.6
                color: #bdcfd1
            &__arrow
                position: absolute
                bottom: 18px
                right: 18px
                width: 39px
                height: 39px
                background-color: #ffffff
                border-radius: 50%
                span
                    position: absolute
                    top: 15px
                    left: 14px
                    width: 13px
                    height: 3px
                    background-color: #bdcfd1
                    border-radius: 20px
                    transform: rotate(45deg)
                    &:after
                        content: ''
                        position: absolute
                        top: 5px
                        left: 5px
                        width: 13px
                        height: 3px
                        background-color: #bdcfd1
                        border-radius: 20px
                        transform: rotate(90deg)

        &__content
            margin-left: 155px
            max-width: 495px
        &__name
            font-size: 23px
            font-family: 'manrope', Arial, sans-serif
            font-weight: 300
        &__position
            max-width: 280px
            margin-bottom: 20px
            font-size: 14px
            color: $gray-base
        &__text
            font-size: 18px
            font-family: 'manrope', Arial, sans-serif
            font-weight: 300

    &-people
        padding-top: 180px
        opacity: 0
        transition: opacity .7s
        &__column
            padding-left: 86px
        &__container
            display: flex
        &__img
            margin: 78px 0 0 calc((100% - 1440px)/2)
            padding: 180px 0 30px 70px
            overflow: hidden
            img
                width: 100%
                height: 100%
                object-fit: cover
            &-1
                margin-top: 40px
                margin-right: 240px
                width: 435px
                min-width: 435px
                height: 308px
            &-2
                position: absolute !important
                right: 38%
                width: 266px
                min-width: 266px
                height: 178px
            &-3
                position: absolute !important
                right: 3%
                margin-top: -180px
                width: 429px
                min-width: 429px
                height: 285px
            &-4
                margin-left: 530px
                margin-top: -100px
                margin-right: 108px
                width: 228px
                min-width: 228px
                height: 285px
            &-5
                width: 451px
                min-width: 451px
                height: 337px
            &-1, &-2, &-3, &-4, &-5
                position: relative
                .t-background
                    position: absolute
                    width: 100%
                    height: 100%
                    top: 28px
                    left: 28px
                    z-index: -1
        .t-main-quote__line._bottom
            bottom: 30px
        .t-main-quote
            &__container
                text-align: end
                padding-right: 52px
    &-salary
        padding-top: 180px
        opacity: 0
        transition: opacity .7s
        &__block
            margin-bottom: 100px

._open-block
    opacity: 1

@media (max-width: 1440px)
    .t-main
        &-company
            &-swiper
                margin: 0
                max-width: 100%

@media (max-width: 1320px)
    .t-main
        &-people
            &__img
                margin: 40px 0 0 -120px

        &-company
            &-swiper
                padding-left: calc( 86px + 80px )
                //max-width: calc(675px + 80px + 86px)
                .swiper-slide
                    &:last-child
                        margin-right: 80px !important
                .swiper-wrapper
                    width: fit-content !important

@media (max-width: 1320px)
    .t-main
        padding-top: 230px
        &-top
            height: 280px
        &-slider
            max-width: 580px
            clip-path: polygon(0 100%, calc(100% - 53.3%) 0, 100% 0, 53.3% 100%)
            -webkit-clip-path: polygon(0 100%, calc(100% - 53.3%) 0, 100% 0, 53.3% 100%)
            &__pattern
                right: -72px
                clip-path: polygon(0 100%, calc(100% - 15.3%) 0, 100% 0, 15.3% 100%)
                -webkit-clip-path: polygon(0 100%, calc(100% - 15.3%) 0, 100% 0, 15.3% 100%)
            img
                clip-path: polygon(0 100%, calc(100% - 53.3%) 0, 100% 0, 53.3% 100%)
                -webkit-clip-path: polygon(0 100%, calc(100% - 53.3%) 0, 100% 0, 53.3% 100%)
            .swiper-container
                height: 280px
        &-swiper-pagination
            width: 315px

        &-it
            &-department-block
                &__title
                    //font-size: 22px
                &__text
                    font-size: 16px

@media (max-width: 1250px)
    .t-main
        &-it
            &__container
                max-width: 450px
            &__img
                right: 40px
            &-department__content
                margin-left: 100px
                max-width: 410px
            &-department-block__item
                padding: 15px 0 15px 18px

        &-bank
            padding-left: 320px
            &__btn
                margin-left: 320px

        &-quote
            &__line
                right: calc(100% - 520px)
                width: calc(520px)
            &__container
                padding-left: 320px

        &-company
            &-swiper2
                .swiper-slide
                    //width: 378px !important

        &-people
            &__img
                margin: 0 auto
                max-width: 1010px
                padding: 180px 0 30px 0
                &-1
                    margin-top: 0
                    margin-right: 45px
                &-2
                    position: relative
                    margin-top: 200px
                    right: 3%
                &-3
                    margin-top: -115px
                    margin-left: -200px
                &-4
                    margin-left: 100px
                    margin-top: 28px
                    margin-right: 38px
                &-5
                    margin-top: 100px

@media (max-width: 1080px)
    .t-main
        &-it
            &-department__content
                margin-left: 0

@media (max-width: 1000px)
    .t-main
        &-top
            height: 225px
        &-slider
            max-width: 480px
            clip-path: polygon(0 100%, calc(100% - 53.3%) 0, 100% 0, 53.3% 100%)
            -webkit-clip-path: polygon(0 100%, calc(100% - 53.3%) 0, 100% 0, 53.3% 100%)
            &__pattern
                right: -72px
                clip-path: polygon(0 100%, calc(100% - 15.3%) 0, 100% 0, 15.3% 100%)
                -webkit-clip-path: polygon(0 100%, calc(100% - 15.3%) 0, 100% 0, 15.3% 100%)
            img
                clip-path: polygon(0 100%, calc(100% - 53.3%) 0, 100% 0, 53.3% 100%)
                -webkit-clip-path: polygon(0 100%, calc(100% - 53.3%) 0, 100% 0, 53.3% 100%)
            .swiper-container
                height: 225px
        &-swiper-pagination
            width: 265px

        &-container
            margin-bottom: 165px
            .t-title
                width: fit-content
                img
                    right: -20px
                    transform: scale(.9)

        &-btn
            margin-bottom: 70px

        &-it
            &__container
                margin-left: 0
            &__img
                right: 0
                width: 96px
                height: 96px
                img
                    transform: scale(.55)
            &__img-1
                top: -120px
                left: -20px
            &__img-2
                top: -90px
                right: 3px
            &-department-block
                &__title
                    font-size: 22px
                &__text
                    font-size: 13px
                    padding-right: 40px
                &__item
                    img
                        bottom: -14px
                        right: -10px
                        transform: scale(.8)
                    &:not(:last-child)
                        margin-right: 10px
                    &._all .t-main-it-department-block__title
                        margin: 14px 0 5px
                        max-width: 120px
                    &._all .t-main-it-department-block__text
                        font-size: 13px
                &__arrow
                    transform: scale(.8)
                    bottom: 16px
                    right: 5px
            &__btn
                margin-bottom: 160px

            &-department__content
                margin: 0 auto

        &-bank
            padding-left: 200px
            &__img
                left: 0
                top: 50px
                width: 96px
                height: 96px
                span
                    width: 10px
                    bottom: -7px
                &-1
                    left: -5px
                &-2
                    left: 20px
                    height: 86px
                &-3
                    left: 45px
                    height: 124px
                &-4
                    left: 70px
                    height: 160px
            &__btn
                margin-left: 200px
                margin-bottom: 75px

        &-quote
            &__line
                display: none
                &._mobile
                    display: flex
                    width: 100%
                    top: 80px
                    height: 15px
                    background-size: cover
                    right: 0
            &__container
                padding-left: 0
                margin-bottom: 60px

        &-product
            &__container img
                max-height: 380px
            &__img
                margin-top: 42px

        &-str
            padding: 16px 0 16px
            height: 51px
            &__content
                font-size: 18px

        &-company
            &-swiper
                padding-left: calc( 86px + 40px )
                //max-width: calc(675px + 40px + 86px)
                .swiper-slide
                    &:last-child
                        margin-right: 40px !important
                .swiper-wrapper
                    width: fit-content !important

@media (max-width: 842px)
    .t-main
        &-block
            &__item
                &:nth-child(3n)
                    margin-right: 20px
                &:nth-child(2n)
                    margin-right: 0
                &:last-child
                    width: 288px
                    margin-right: 0

        &-company
            &-swiper
                max-width: 100%
                .swiper-slide
                    &:last-child
                        margin-right: 80px !important

@media (max-width: 767px)
    .t-main
        &-container
            h1
                margin-bottom: 10px

        &-btn
            margin: 35px 0
            &__left
                margin-right: 12px

        &-top
            height: 180px

        &-slider
            margin-top: 35px
            max-width: 340px
            margin-right: -100px
            clip-path: polygon(0 100%, 42.7% 0, 100% 0, 71.3% 100%)
            -webkit-clip-path: polygon(0 100%, 42.7% 0, 100% 0, 71.3% 100%)
            &__pattern
                top: -35px
                right: -6px
                z-index: -1
            img
                clip-path: polygon(0 100%, 42.7% 0, 100% 0, 71.3% 100%)
                -webkit-clip-path: polygon(0 100%, 42.7% 0, 100% 0, 71.3% 100%)
            .swiper-container
                height: 144px

        &-bank
            padding-left: 0
            &__btn
                margin-bottom: 0

        &-it, &-bank
            padding: 12px 16px 16px
            border: 1px solid rgba(#bdcfd1, .5)
            background-color: #ffffff
            border-radius: 16px 30px 16px 16px
            &__img
                left: 16px
                right: auto
                top: -60px
                z-index: -1
            &__btn
                padding: 16px 78px 16px 16px !important
                margin-left: 0
                width: 100%
            .t-title
                position: relative
                width: calc(100% - 50px)
                max-width: 400px
                img
                    top: -22px
                    right: -78px

        &-quote
            &__title
                flex-direction: column
            &__face
                width: 95px
                height: 95px
                margin-right: 0
                margin-bottom: 19px
            &__position
                width: fit-content
                margin: 0 auto
            &__text
                padding: 13px 0
                text-align: center
                position: relative
                white-space: normal
                line-height: 154%
                &:after
                    content: ''
                    position: absolute
                    top: 0
                    left: calc(50% - 77px)
                    width: 154px
                    height: 100%
                    border-top: 1px solid rgba(#bdcfd1, .4)
                    border-bottom: 1px solid rgba(#bdcfd1, .4)
                    z-index: -1

        &-block
            margin-top: 40px
            padding-top: 46px
            &__title
                padding: 0 20px
                margin-bottom: 40px
                line-height: 1.57
                font-size: 14px
                letter-spacing: 5px
            &__item
                width: 138px
                margin-bottom: 12px
                &:not(:nth-child(3n)), &:nth-child(3n)
                    margin-right: 12px
                &:nth-child(2n)
                    margin-right: 0
                &:last-child
                    margin-right: 0
                &._all
                    height: 46px
                    background-color: rgba(#bdcfd1, .26)
                    border-color: rgba(#bdcfd1, .26)
                    display: flex
                    justify-content: center
                    border-radius: 10px
                    img
                        display: none
                img
                    transform: scale(.65)
                    margin: -33px 0 0 -34px
            &__subtitle
                font-size: 14px
                line-height: 1.36
            &__container
                max-width: 300px
                margin-bottom: 70px

        &-company
            padding: 20px 0 0
            &__text
                margin: 16px 0 30px 16px
            .t-main-cupis
                margin-bottom: 70px
                max-width: 100%
            &-swiper
                padding-left: 32px
                .swiper-slide
                    &:last-child
                        margin-right: 48px !important
                .swiper-wrapper
                    width: fit-content !important
            &-slider
                margin-bottom: 95px !important
                .t-swiper-nav
                    display: none
                &__item
                    padding: 10px 18px 20px
                    width: auto
                    height: 100%
                    //width: 238px
                    //height: 132px
                &__container
                    margin-bottom: 12px
                    span
                        font-size: 16px
                &__number
                    width: 28px
                    height: 28px
                &__text
                    font-size: 14px
                &__line:after
                    height: 5px
            &__quote
                padding-top: 64px

            &-swiper2
                padding: 30px 0 0 16px
                margin-bottom: 95px !important
                .t-swiper-nav
                    display: none
                .swiper-slide
                    //width: 100% !important
                    padding-right: 16px
                &__name
                    font-size: 16px
                &__position
                    font-size: 12px
                &__text
                    font-size: 14px
                &__pagination
                    display: flex
                &__img
                    left: 16px
                    width: 20px
                    height: 14px
                &__img2
                    left: 30px
                    width: 20px
                    height: 14px

        &-product
            padding-top: 80px
            &__block
                padding: 16px
            &__subtitle
                font-size: 14px
                margin-bottom: 10px
            &__text
                font-size: 14px
            &__img
                padding-bottom: 87vw
                margin-bottom: 0
                img:last-child
                    position: absolute
                    bottom: 0
                    left: 0
                    max-height: 500px
                    height: auto
                    width: 100%
                .t-background
                    top: 50px
                    height: 370px
            &__container.t-work-area-fullRight
                display: grid
                grid-template-columns: repeat(2, minmax(0, 342px))
                img
                    max-width: 100%

        &-it-department
            padding-top: 80px
            &-block
                .t-background
                    height: 263px
                    top: calc(50% - 131px)
                &__container
                    flex-wrap: wrap
                    padding-left: 0
                &__item
                    margin: 0 0 10px 0
                    width: 270px
                    &:nth-child(even)
                        margin-left: 16px
                &__title
                    font-size: 18px
                    font-weight: 600
                &__position
                    font-size: 13px
            &__content
                padding: 60px 16px 0
            &__text
                font-size: 14px

        &-people
            padding-top: 80px
            &__column
                padding-left: 0
            &__img
                padding: 80px 0 30px 0
                &-1
                    width: 180px
                    min-width: 180px
                    height: 185px
                    .t-background
                        top: 17px

                &-2
                    margin-top: 103px
                    width: 200px
                    min-width: 200px
                    height: 142px
                    right: -78px
                    .t-background
                        top: 22px

                &-3
                    right: -75px
                    margin-top: -50px
                    margin-left: 0
                    width: 180px
                    min-width: 180px
                    height: 125px
                    .t-background
                        top: 17px

                &-4
                    margin-top: 32px
                    margin-left: -80px
                    width: 197px
                    min-width: 197px
                    height: 200px
                    .t-background
                        top: 18px

                &-5
                    position: absolute
                    right: -35px
                    margin-top: 92px
                    width: 200px
                    min-width: 200px
                    height: 164px
                    .t-background
                        top: 15px

        &-salary
            padding-top: 80px
            &__block
                margin-bottom: 60px
